import React, { useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { withRouter } from "react-router";
import { AGENCY_RESTRICTIONS, filterRestrictions } from "@local/legacy-utils/restrictions";
import { connect } from "react-redux";
import style from "./style.module.scss";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { ROLES } from "@local/utils/constants";
import { sessionChange, sessionInitializing, loadUserData, sessionSwitchAgencyOffice } from "@local/legacy-reducers/session";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { checkPermission, COMMON_PERMISSIONS } from "../../legacy-utils/permissions";
import GroupIcon from "@mui/icons-material/Group";
import { getBaseUrl } from "@local/utils/network";
import ExternalIcon from "@mui/icons-material/OpenInNew";
import { AdminPanelSettings } from "@mui/icons-material";
import i18n from "@local/i18next/i18n";

const SideBar = ({
  items,
  history,
  agencyName,
  partnerName,
  logo,
  role,
  startRefresh,
  stopRefresh,
  isCurrentHq,
  currentHqId,
  partnerId,
  loadUserInfo,
  saveNewCurrentPartner,
  saveNewCurrentOffice,
  partners,
  partnerCountry,
  countries,
  officeName,
  offices,
  hasPermissionToManageUsers,
  hasAdminPanelPermission,
}) => {
  const [anchorElement, setAnchorElement] = useState(undefined);

  const onOpenSwitch = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const onCloseSwitch = () => {
    setAnchorElement(undefined);
  };

  const onClickPartnerSwitch = (partner) => {
    saveNewCurrentPartner({
      partnerId: partner.id,
      partnerCountry: partner.country_code,
      partnerName: partner.legal_name,
      isHq: partner.is_hq,
      hqId: currentHqId || isCurrentHq ? partnerId : null,
      logo: partner.logo,
      logoThumbnail: partner?.org_logo_thumbnail ?? partner.logoThumbnail,
      isProfileComplete: partner.partner_additional.has_finished,
      lastUpdate: partner.last_profile_update,
    });
    startRefresh();
    loadUserInfo().then(() => {
      history.push("/");
      stopRefresh();
    });
    setAnchorElement(undefined);
  };

  const onClickPartnerAgencySwitch = (agency) => {
    saveNewCurrentOffice({
      officeId: agency.office.id,
      officeName: agency.office.name,
      officeRole: agency.role_display,
      permissions: agency.permissions,
      officeCountryCode: agency.office?.country,
    });
    startRefresh();
    loadUserInfo().then(() => {
      history.push("/");
      stopRefresh();
    });
    setAnchorElement(undefined);
  };

  const onClickItem = (item) => {
    if (item.external) {
      window.open(item.path, "_blank").focus();
    } else {
      history.push(item.path);
    }
  };

  const primaryItems = items.filter((item) => !item.external);
  const secondaryItems = items.filter((item) => item.external);
  const isSwitchOpen = Boolean(anchorElement);
  const partner = partners.length ? partners.find((item) => item.id === partnerId) : undefined;

  return (
    <Box>
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          width: 240,
          flexShrink: 0,
          "& .MuiDrawer-paper": { width: 240, boxSizing: "border-box" },
        }}
      >
        <Toolbar />
        <div className={style.divider} />
        <Box
          sx={{
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <List>
            {primaryItems.map((item) => (
              <ListItem sx={{ pt: ".25rem", pb: ".25rem" }} button key={item.label} selected={window.location.pathname.startsWith(item.path)} onClick={() => onClickItem(item)}>
                <ListItemIcon sx={{ minWidth: "2rem" }}>{item.icon}</ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontSize: ".9rem" }} primary={item.label} />
              </ListItem>
            ))}
            {secondaryItems.map((item) => (
              <ListItem sx={{ pt: ".25rem", pb: ".25rem" }} button key={item.label} onClick={() => onClickItem(item)}>
                <ListItemIcon sx={{ minWidth: "2rem" }}>{item.icon}</ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontSize: ".9rem" }} primary={item.label} />
                <div className={style.externalIconWrapper}>
                  <ExternalIcon style={{ fill: "#cccccc", fontSize: "1.15rem" }} />
                </div>
              </ListItem>
            ))}
          </List>
          <div className={style.divider} />
          <div className={style.footerWrapper}>
            <div>
              <div className={style.logoNameWrapper}>
                {logo ? <img className={style.logo} alt="" src={`${getBaseUrl()}${logo}`} /> : undefined}
                <div>
                  <span className={style.nameLabel}>{formatMessage({ id: "cmp.side.bar.idx.logged" })}</span>
                  <span className={style.nameValue}>{agencyName || partnerName}</span>
                </div>
              </div>
              {role === ROLES.AGENCY ? (
                <div>
                  <Button variant="outlined" aria-controls={isSwitchOpen ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={isSwitchOpen ? "true" : undefined} onClick={onOpenSwitch} endIcon={<KeyboardArrowDownIcon />} size="small" fullWidth>
                    {officeName}
                  </Button>
                  <Menu id="basic-menu" anchorEl={anchorElement} open={isSwitchOpen} onClose={onCloseSwitch} MenuListProps={{ "aria-labelledby": "basic-button" }}>
                    {offices.map((item, index) => (
                      <MenuItem key={`agency_switch_${index}`} onClick={() => onClickPartnerAgencySwitch(item)}>
                        {item.office.name}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              ) : (
                <div>
                  <Button variant="outlined" aria-controls={isSwitchOpen ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={isSwitchOpen ? "true" : undefined} onClick={onOpenSwitch} endIcon={<KeyboardArrowDownIcon />} size="small" fullWidth>
                    {`${partner && partner.is_hq ? "(HQ) " : ""}${partnerCountry} `}
                  </Button>
                  <Menu id="basic-menu" anchorEl={anchorElement} open={isSwitchOpen} onClose={onCloseSwitch} MenuListProps={{ "aria-labelledby": "basic-button" }}>
                    {partners.map((item, index) => (
                      <MenuItem key={`partner_switch_${index}`} onClick={() => onClickPartnerSwitch(item)}>
                        {`${countries[item.country_code]} ${item.is_hq ? " - HQ" : ""}`}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              )}

              {hasPermissionToManageUsers ? (
                <>
                  <div className={style.divider} />
                  <List>
                    <ListItem button onClick={() => onClickItem({ path: "/idp/users" })}>
                      <ListItemIcon sx={{ minWidth: "2rem" }}>
                        <GroupIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: ".9rem" }}
                        primary={formatMessage({
                          id: "cmp.side.bar.idx.button",
                        })}
                      />
                    </ListItem>
                  </List>
                </>
              ) : undefined}
              {hasAdminPanelPermission ? (
                <List>
                  <ListItem button onClick={() => onClickItem({ path: "/admin/projects" })}>
                    <ListItemIcon sx={{ minWidth: "2rem" }}>
                      <AdminPanelSettings fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ fontSize: ".9rem" }} primary={i18n.t("Admin Panel")} />
                  </ListItem>
                </List>
              ) : undefined}
            </div>
          </div>
        </Box>
      </Drawer>
    </Box>
  );
};

SideBar.propTypes = {};

const mapStateToProps = (state) => ({
  items: filterRestrictions(state.nav, AGENCY_RESTRICTIONS, state.session),
  role: state.session.role,
  agencyName: state.session.agencyName,
  partnerName: state.session.partnerName,
  logo: state.session.logo?.file_field ?? undefined,
  partners: state.session?.partners ?? [],
  partnerCountry: state.countries && state.session.partnerCountry ? state.countries[state.session.partnerCountry] : "",
  countries: state.countries,
  partnerId: state.session?.partnerId ?? undefined,
  isCurrentHq: state.session?.isHq ?? false,
  currentHqId: state.session?.hqId ?? undefined,
  officeName: state.session?.officeName,
  offices: state.session?.offices ?? [],
  hasPermissionToManageUsers: checkPermission(COMMON_PERMISSIONS.MANAGE_USERS, state),
  hasAdminPanelPermission: checkPermission(COMMON_PERMISSIONS.ADMIN_PANEL, state), // we need to add admin panel permissions
});

const mapDispatchToProps = (dispatch) => ({
  saveNewCurrentPartner: (session) => dispatch(sessionChange(session)),
  saveNewCurrentOffice: (session) => dispatch(sessionSwitchAgencyOffice(session)),
  loadUserInfo: () => dispatch(loadUserData()),
  startRefresh: () => dispatch(sessionInitializing()),
  stopRefresh: () => dispatch(sessionChange()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideBar));
