/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Main from "./components/main";
import { connect } from "react-redux";
import { getPartnersBasicInformationList, createFirstAccessPartner, getCountriesList, createPartnerRegistration, shellProfileCurrent, createAgencyMember } from "@local/actions";
import { toast } from "react-toastify";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { change, reset } from "redux-form";
import { getPartnerTypesWithoutSpecialTypes } from "@local/utils/constants";
import { adaptCountries } from "@local/utils/adapters";
import AlertModal from "@local/components/alert-modal";
import { useHistory } from "react-router-dom";
import { adaptPartner, getFirstAccessInitialValues } from "./utils/adapters";
import { sessionChange, logoutUser } from "@local/legacy-reducers/session";
import { getErrors } from "@local/utils";

const FirstAccess = ({
  step,
  getPartnersBasicInformation,
  formValues,
  createFirstAccessPartner,
  createAgencyMember,
  resetForm,
  getCountries,
  countries,
  partnerTypes,
  changeIsHqField,
  changeDisplayTypeField,
  createPartnerRegistration,
  updateSession,
  isShellProfile,
  shellProfileCurrent,
  logout,
  role,
  agencyCountryOffices,
}) => {
  const [currentStep, setCurrentStep] = useState(role === "agency" ? "agency-access" : "welcome");
  const [fullName, setFullName] = useState("");
  const [newPartnerCurrentStep, setNewPartnerCurrentStep] = useState(0);
  const [isHqAlertModalVisible, setIsHqAlertModalVisible] = useState(false);
  const [isMissingDocumentAlertModalVisible, setIsMissingDocumentAlertModalVisible] = useState(false);
  const [isMissingDeclarationAlertModalVisible, setIsMissingDeclarationAlertModalVisible] = useState(false);
  const [shellProfileDetails, setShellProfileDetails] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  useEffect(() => {
    setFullName(localStorage.getItem("welcome-full-name") ?? "");
    switch (step) {
      case "registration":
        setCurrentStep("partner-steps");
        break;
      case "first-access":
        if (role === "agency") setCurrentStep("agency-access");
        else setCurrentStep("welcome");
        break;
      case "confirmation":
        setCurrentStep("welcome-confirmation");
        break;
      default:
        setCurrentStep("");
        break;
    }
  }, [step]);

  const getShellProfile = async () => {
    const response = await shellProfileCurrent();
    setShellProfileDetails(response?.data);
    return response?.data;
  };

  useEffect(() => {
    if (isShellProfile) {
      setCurrentStep("shell-profile");
      getShellProfile();
    }
  }, [isShellProfile]);

  const onClickBack = () => {
    if (isShellProfile) setCurrentStep("shell-profile");
    else setCurrentStep("welcome");
    resetForm();
    setNewPartnerCurrentStep(0);
  };

  const onClickContinueAsPartner = () => {
    setCurrentStep("partner-welcome");
  };

  const onClickRequestPartnerAccess = async () => {
    try {
      const { partner, admin } = formValues;
      const body = { partner: partner.id, admin };
      await createFirstAccessPartner(body);
      localStorage.setItem("welcome-full-name", partner.legal_name);
      setCurrentStep("welcome-confirmation");
      setFullName(partner.legal_name);
      toast.success(`An email have been sent to ${partner.legal_name}`);
      resetForm();
    } catch (error) {
      toast.error(formatMessage({ id: "pages.first.access.idx.error" }));
    }
  };

  const onClickCreateNewPartnerProfile = () => {
    setCurrentStep("partner-steps");
    setNewPartnerCurrentStep(0);
    resetForm();
  };

  const onClickCreateNewAgencyMember = async () => {
    try {
      await createAgencyMember({
        country_code: formValues?.agency_country_offices,
      });
      updateSession();
      history.push("/");
    } catch (error) {
      toast.error(formatMessage({ id: "pages.first.access.idx.errorOne" }));
    }
  };

  const onClickSendNewRequest = () => {
    localStorage.removeItem("welcome-full-name");
    setCurrentStep("partner-welcome");
  };

  const onClickPreviousStep = () => {
    switch (newPartnerCurrentStep) {
      case 0:
        setNewPartnerCurrentStep(0);
        break;
      case 1:
        setNewPartnerCurrentStep(0);
        break;
      case 2:
        setNewPartnerCurrentStep(1);
        break;
      case 3:
        setNewPartnerCurrentStep(2);
        break;
      case 4:
        setNewPartnerCurrentStep(3);
        break;
      default:
        setNewPartnerCurrentStep(0);
        break;
    }
  };

  const onClickNextStep = async () => {
    switch (newPartnerCurrentStep) {
      case 0:
        setNewPartnerCurrentStep(1);
        break;
      case 1:
        setNewPartnerCurrentStep(2);
        break;
      case 2:
        if (formValues?.registered_to_operate_in_country === "false" && formValues?.have_governing_document === "false") {
          setIsMissingDocumentAlertModalVisible(true);
        } else {
          setNewPartnerCurrentStep(3);
        }
        break;
      case 3:
        if (
          formValues?.ad_1 === "false" ||
          formValues?.ad_2 === "false" ||
          formValues?.ad_3 === "false" ||
          formValues?.ad_4 === "false" ||
          formValues?.ad_5 === "false" ||
          formValues?.ad_6 === "false" ||
          formValues?.ad_7 === "false" ||
          formValues?.ad_8 === "false" ||
          formValues?.ad_9 === "false" ||
          formValues?.ad_10 === "false" ||
          formValues?.ad_11 === "false" ||
          formValues?.ad_12 === "false"
        ) {
          setIsMissingDeclarationAlertModalVisible(true);
        } else {
          setNewPartnerCurrentStep(4);
        }
        break;
      case 4:
        break;
      default:
        break;
    }
    if (newPartnerCurrentStep === 4) {
      try {
        const body = adaptPartner(formValues);
        await createPartnerRegistration(body);
        updateSession();
        localStorage.setItem("newlyRegistered", "true");
        history.push("/");
      } catch (error) {
        const errorMessages = getErrors(error ?? {}).join(" ");
        toast.error(errorMessages || formatMessage({ id: "pages.first.access.idx.errorOne" }));
      }
    }
  };

  const onChangeIsHq = (isHq) => {
    if (isHq === "false") setIsHqAlertModalVisible(true);
  };

  const onClickCloseHqAlertModal = () => {
    setIsHqAlertModalVisible(false);
    changeIsHqField();
    changeDisplayTypeField();
  };

  const onClickCloseMissingDocumentAlertModal = () => {
    setIsMissingDocumentAlertModalVisible(false);
  };

  const onClickCloseMissingDeclarationAlertModal = () => {
    setIsMissingDeclarationAlertModalVisible(false);
  };

  const onClickLogout = () => logout();

  return (
    <>
      <Main
        onClickBack={onClickBack}
        currentStep={currentStep}
        onClickContinueAsPartner={onClickContinueAsPartner}
        getPartnersBasicInformation={getPartnersBasicInformation}
        onClickRequestPartnerAccess={onClickRequestPartnerAccess}
        onClickCreateNewPartnerProfile={onClickCreateNewPartnerProfile}
        onClickCreateNewAgencyMember={onClickCreateNewAgencyMember}
        fullName={fullName}
        onClickSendNewRequest={onClickSendNewRequest}
        newPartnerCurrentStep={newPartnerCurrentStep}
        onClickNextStep={onClickNextStep}
        onClickPreviousStep={onClickPreviousStep}
        partnerTypes={partnerTypes}
        countries={countries}
        formValues={formValues}
        onChangeIsHq={onChangeIsHq}
        initialValues={isShellProfile ? getFirstAccessInitialValues(shellProfileDetails) : undefined}
        onClickLogout={onClickLogout}
        role={role}
        agencyCountryOffices={agencyCountryOffices}
      />
      <AlertModal isOpen={isHqAlertModalVisible} onClose={onClickCloseHqAlertModal} message={formatMessage({ id: "pages.first.access.idx.alertDialog" })} />
      <AlertModal isOpen={isMissingDocumentAlertModalVisible} onClose={onClickCloseMissingDocumentAlertModal} message={formatMessage({ id: "pages.first.access.idx.legalInfo" })} />
      <AlertModal
        isOpen={isMissingDeclarationAlertModalVisible}
        onClose={onClickCloseMissingDeclarationAlertModal}
        message={formatMessage({
          id: "pages.first.access.idx.declarationInfo",
        })}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  formValues: state.form?.firstAccessForm?.values ?? {},
  partnerTypes: getPartnerTypesWithoutSpecialTypes(),
  countries: adaptCountries(state?.countriesList?.data?.data ?? {}),
  isShellProfile: state?.session?.profileType === "shell-profile",
  role: state?.session?.role ?? {},
  agencyCountryOffices: adaptCountries(state?.session?.agencyCountryOffices ?? {}),
});

const mapDispatchToProps = (dispatch) => ({
  getPartnersBasicInformation: (value) =>
    dispatch(
      getPartnersBasicInformationList({
        is_hq: "False",
        legal_name: value,
        extra: "no_flags=FL4_Red",
        page_size: 10,
        exclude_special_partners: "True",
      }),
    ),
  createFirstAccessPartner: (body) => dispatch(createFirstAccessPartner(body)),
  createPartnerRegistration: (body) => dispatch(createPartnerRegistration(body)),
  createAgencyMember: (body) => dispatch(createAgencyMember(body)),
  resetForm: () => dispatch(reset("firstAccessForm")),
  getCountries: () => dispatch(getCountriesList()),
  changeIsHqField: () => dispatch(change("firstAccessForm", "is_hq", null)),
  changeDisplayTypeField: () => dispatch(change("firstAccessForm", "display_type", null)),
  updateSession: () => dispatch(sessionChange({ newlyRegistered: true })),
  shellProfileCurrent: () => dispatch(shellProfileCurrent()),
  logout: () => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FirstAccess);
