import React from "react";
import { formatMessage } from "./i18nHelper";

// roles
export const ROLES = {
  AGENCY: "agency",
  PARTNER: "partner",
};

// project types
export const PROJECT_TYPES = {
  OPEN: "open",
  PINNED: "pinned",
  DIRECT: "direct",
  UNSOLICITED: "unsolicited",
};

// project details items
export const DETAILS_ITEMS = {
  OVERVIEW: "overview",
  FEEDBACK: "feedback",
  SUBMISSION: "submission",
  RESULTS: "results",
  PRESELECTED: "preselected",
  REQUESTS: "requests",
  APPLICATIONS: "applications",
  RESPONSE: "response",
};

export const APPLICATION_STATUSES = {
  PRE: "Pre",
  REJ: "Rej",
  PEN: "Pen",
  REC: "Rec",
  REV: "Rev",
};

export const SESSION_STATUS = {
  INITIAL: "initial",
  CHANGING: "changing",
  READY: "ready",
};

export const AGENCY_MEMBERS_POSITIONS = {
  ADMIN: "Administrator",
  EDITOR: "Editor",
  READER: "Reader",
};

export const PROJECT_STATUSES = {
  OPE: "Ope",
  CLO: "Clo",
  COM: "Com",
  DRA: "Dra",
  SEN: "Sen",
  REV: "Rev",
};

export const APPLICATION_REVIEW_CRITERIA = {
  SEE: "Sector expertise and experience",
  Pro: "Project management",
  LEP: "Local experience and presence",
  Con: "Contribution of resource",
  Cos: "Cost effectiveness",
  Exp: "Experience working with UN",
  Rel: "Relevance of proposal to achieving expected results",
  Cla: "Clarity of activities and expected results",
  Inn: "Innovative approach",
  Sus: "Sustainability of intervention",
  Rea: "Realistic timelines and plans",
  ASC: "Access/security considerations",
  Oth: "Other",
};

export const FLAGS = {
  OBSERVATION: "FL1_Obs",
  YELLOW: "FL2_Yel",
  ESCALATED: "FL3_Esc",
  RED: "FL4_Red",
  SANCTION: "C90_sanctions_match",
};

export const PLACEHOLDERS = {
  list: "Please list",
  provide: "Please provide",
  select: "Please select",
  explain: "Briefly explain",
  indicate: "Please indicate",
  state: "Please state",
  comment: "Provide comment",
};

export const COUNTRIES = {
  palestine: "PS",
};

export const DELIMITER = "|";

export const FAQ_LIST = [
  {
    question: "How do I register?",
    answer: (
      <div>
        Please see this{" "}
        <a href="https://unpartnerportalcso.zendesk.com/hc/en-us/articles/360004110374-Registration" target="_blank" rel="noreferrer">
          link
        </a>{" "}
        with guidance and video on how to register your organization on the portal. Additionally, please be reminded to always use Chrome as your browser whilst accessing UN Partner Portal and clear the cache regularly.
      </div>
    ),
  },
  {
    question: "How do I assign roles and add users?",
    answer: (
      <div>
        If you are an administrator you are able to add users and assign them roles for your organization. Please follow the steps in this{" "}
        <a href="https://unpartnerportalcso.zendesk.com/hc/en-us/articles/360013423454-User-Management-Assignment-of-users-in-your-organization-" target="_blank" rel="noreferrer">
          link
        </a>
        .
      </div>
    ),
  },
  {
    question: "My profile has been migrated from the old portal, how do I access?",
    answer: (
      <div>
        Please follow the steps in this{" "}
        <a href="https://unpartnerportalcso.zendesk.com/hc/en-us/articles/360014082374-Login-as-a-Migrated-Partner-from-UNHCR-s-Partner-Portal" target="_blank" rel="noreferrer">
          link
        </a>{" "}
        which provides guidance on how to access your organization's account as a migrated partner.
      </div>
    ),
  },
  {
    question: "Do we complete each country profile with the same answers/do we treat each country as a separate entity?",
    answer: <div>Presently the only way is to upload the documents on to each profile and complete the required data in each profile.</div>,
  },
  {
    question: "How to pin the location on the map",
    answer: (
      <div>
        This{" "}
        <a href="https://unpartnerportalcso.zendesk.com/hc/en-us/articles/360004492133-Create-and-Edit-Profile-Local-NGO" target="_blank" rel="noreferrer">
          video
        </a>{" "}
        (minute 01:57) gives guidance on how to pin the location on the map.
      </div>
    ),
  },
  {
    question: "How to add users in the different country profiles as an HQ administrator",
    answer: (
      <div>
        Please follow{" "}
        <a href="https://unpartnerportalcso.zendesk.com/hc/en-us/articles/360013423454-User-Management-Assignment-of-users-in-your-organization-" target="_blank" rel="noreferrer">
          this
        </a>{" "}
        user guide where you can find information on how to add users in the different country profiles as an HQ administrator. Please do not change the user name but add the new user.
      </div>
    ),
  },
  {
    question: "Difference between Concept Note Template and the Program Document Template",
    answer: (
      <div>
        The Concept Note template is used by all CSOs interested in responding to a UNICEF CFEI. The content required for a Concept Note consists of information that can be readily provided by a CSO (in the absence of UNICEF intervention). You are correct in noting that
        the Concept Note template bears strong resemblances to the Programme Document template. The idea is that the CSO who submits the strongest Concept Note demonstrating the greatest comparative advantage will be selected by UNICEF, and requested to "convert" its
        Concept Note into a PD. This conversion process is expected to be swift, given the similarities between the two templates. The idea is that a PD developed in this way will be able to rapidly proceed to PRC review.
      </div>
    ),
  },
  {
    question: "How to add/create a new country profile",
    answer: (
      <div>
        To add a new country, please take the following steps:
        <ol>
          <li>Click on profile</li>
          <li>Click on HQ profile</li>
          <li>Click edit</li>
          <li>Click mandate and missions tab</li>
          <li>In subsection Country presence, add the name of the country you want to add if not there already</li>
          <li>Click save and exit</li>
          <li>Click profile</li>
          <li>Click '+New' as in screenshot below</li>
          <li>Click the country and click create</li>
        </ol>
        <img src="https://icc-unpp-data.s3.amazonaws.com/public/faq-screenshot-1.png" alt="Add New" />
      </div>
    ),
  },
  {
    question: "Unable to save the page whilst updating country profile - error while saving sections, please try again",
    answer: (
      <div>
        In order to resolve the issue, please ensure you do the following:
        <ol>
          <li>Please enter/complete all required data on the page</li>
          <li>Please be reminded to always use Chrome as your browser whilst accessing UN Partner Portal and clear the cache regularly</li>
        </ol>
      </div>
    ),
  },
  {
    question: "How to reset/change password",
    answer: (
      <div>
        If you would like to change your password, click on the forgot password hyperlink (see image below) and follow the steps on the screen.
        <img src="https://icc-unpp-data.s3.amazonaws.com/public/faq-screenshot-2.png" alt="Password Reset" />
      </div>
    ),
  },
  {
    question: "How to upload Partner Declaration",
    answer: (
      <div>
        If you are a newly registered organization on the UN Partner Portal, then your partner declaration would be automatically saved in your organization's profile therefore no further actions on your side. If you are a migrated partner from UNHCR's Partner Portal or
        you need to upload a new file, then you would need to fill the attached form, sign it and upload it in the other information section of your profile.
        <img src="https://icc-unpp-data.s3.amazonaws.com/public/faq-screenshot-3.png" alt="Profile" />
      </div>
    ),
  },
  {
    question: "I got an error while saving section",
    answer: <div>Please try to log out of the system and sign in again to try saving the changes? Furthermore, please clear the browser's cache and use only Chrome.</div>,
  },
  {
    question: "How do I submit a Concept Note",
    answer: (
      <div>
        Please see this{" "}
        <a href="https://unpartnerportalcso.zendesk.com/hc/en-us/articles/360004232134-View-and-Apply-for-Calls-for-Expression-of-Interest" target="_blank" rel="noreferrer">
          link
        </a>{" "}
        that gives guidance on how to submit a concept note for your organization
      </div>
    ),
  },
];

export const UN_DOMAINS = ["@unicef.", "@unhcr.", "@wfp.", "@un.", "@unfpa."];

export const UN_AGENCIES = [
  {
    value: "UN_SECRETARIAT",
    label: "UN SECRETARIAT",
  },
  {
    value: "UN_WOMEN",
    label: "UN Women",
  },
  {
    value: "FAO",
    label: "FAO",
  },
  {
    value: "UNFPA",
    label: "UNFPA",
  },
  {
    value: "UNHCR",
    label: "UNHCR",
  },
  {
    value: "UNICEF",
    label: "UNICEF",
  },
  {
    value: "WFP",
    label: "WFP",
  },
  {
    value: "WHO",
    label: "WHO",
  },
  {
    value: "IOM",
    label: "IOM",
  },
  {
    value: "UNOPS",
    label: "UNOPS",
  },
];

export const UN_EXTRA_AGENCIES = [
  {
    value: "OCHA",
    label: "OCHA",
  },
  {
    value: "DECA",
    label: "DECA",
  },
  {
    value: "UPU",
    label: "UPU",
  },
  {
    value: "WIPO",
    label: "WIPO",
  },
];

export const UN_ALL_AGENCIES = [
  ["UNPP Agencies", [...UN_AGENCIES]],
  ["Other UN Agencies", [...UN_EXTRA_AGENCIES]],
  ["Other", [{ value: "Other", label: "OTHER" }]],
];

export const UN_VENDOR_NUMBER_DOCUMENT_TYPE_CHOICES = [
  {
    value: "ip_agreement",
    label: "IP Agreement",
  },
  {
    value: "amendement_to_ip_agreement",
    label: "Amendment to IP Agreement",
  },
  {
    value: "both_ip_agreement_and_amendment",
    label: "Both IP Agreement and Amendment",
  },
  {
    value: "capacity_building_plan",
    label: "Capacity Building Plan",
  },
  {
    value: "selection_templates",
    label: "Selection templates",
  },
  {
    value: "other",
    label: "Other",
  },
];

export const FEATURES_ENABLED = {
  special_partner: ["UNFPA"],
  document_tabs: ["UNFPA"],
  request_partner_code_button: ["UNFPA"],
};

export const SPECIAL_PARTNERS_TYPE = {
  GOVERNMENT: "GOV",
  UN_AGENCY: "UN",
  GOVERNMENT_ACADEMIC_INSTITUTION: "GAI",
};

export const REPORT_STATUS_FILTER_VALUES = [
  {
    value: "",
    label: formatMessage({ id: "legacy.utils.constants.all" }),
  },
  {
    value: true,
    label: formatMessage({ id: "legacy.utils.constants.yes" }),
  },
  {
    value: false,
    label: formatMessage({ id: "legacy.utils.constants.no" }),
  },
];

export const VERIFICATION_STATUSES = [
  {
    value: "incomplete",
    label: formatMessage({ id: "legacy.utils.constants.profileIncomplete" }),
  },
  {
    value: "pending",
    label: formatMessage({ id: "legacy.utils.constants.profileComplete" }),
  },
  {
    value: "verified",
    label: formatMessage({ id: "legacy.utils.constants.verified" }),
  },
  {
    value: "unverified",
    label: formatMessage({ id: "legacy.utils.constants.unverified" }),
  },
  {
    value: "expired",
    label: formatMessage({ id: "legacy.utils.constants.expired" }),
  },
  {
    value: "invalidated",
    label: formatMessage({ id: "legacy.utils.constants.invalidated" }),
  },
];

export const RISK_FLAGS_REASONS = [
  {
    value: "performance_delivery_issues",
    label: formatMessage({
      id: "legacy.utils.constants.performanceDeliveryIssues",
    }),
  },
  {
    value: "management_administrative_issues",
    label: formatMessage({
      id: "legacy.utils.constants.managementAdministrativeIssues",
    }),
  },
  {
    value: "fraud_or_corruption",
    label: formatMessage({ id: "legacy.utils.constants.fraudOrCorruption" }),
  },
  {
    value: "safeguarding_violation",
    label: formatMessage({
      id: "legacy.utils.constants.safeguardingViolation",
    }),
  },
  {
    value: "others",
    label: formatMessage({ id: "legacy.utils.constants.other" }),
  },
];

export const OBSERVATION_DECISION = {
  NO_VALID: "NV",
  ESCALATE: "EF",
};

export const ESCALATION_DECISION = {
  DEFFERED: "DB",
  CONFIRMED: "EF",
};

export const SANCTION_DECISION = {
  NOTMATCH: "NM",
  CONFIRMED: "CM",
};
