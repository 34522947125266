/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { change, reset } from "redux-form";
import { connect } from "react-redux";
import { getCountriesList, getFocalPointsList, getSectorsList, getPartnersToInviteList, invitePartners } from "@local/actions";
import { adaptCountries, adaptFocalPoints, adaptSpecializations, adaptJustifications, adaptPartnersWithExtendedLabel, adaptCurrencies } from "@local/utils/adapters";
import { getLocationObject, getSortedList } from "@local/utils";
import { toast } from "react-toastify";
import { getConcernTypes, getSelectionCriteria, PROJECT_TYPES } from "@local/utils/constants";
import { adaptProjectToBeSent } from "./utils/adapters";
import { getModalTitle, getModalInformationText, getModalSaveFunction } from "./utils";
import { useHistory } from "react-router-dom";
import ProjectModal from "./components/project-modal";
import InvitePartnersModal from "./components/invite-partners-modal";

// POST_REFACTORING_TODO: In frontend/src/pages/eois/modals/newCfei/newCfeiModal.js check the mining of optionalLocations
// POST_REFACTORING_TODO: If selection a sector, we need to have at least and area for that sector

const ManageProjectModal = ({
  type,
  isOpen,
  onClose,
  countries,
  getCountries,
  formValues,
  focalPoints,
  getFocalPoints,
  agencyId,
  officeId,
  getSectors,
  sectors,
  updateAreas,
  isPopulationFieldVisible,
  concernTypes,
  selectionCriteria,
  saveProject,
  invitePartnersFormValues,
  getPartnersToInvite,
  invitePartners,
  justifications,
  initialValues,
  onAfterSubmit,
  stateValues,
  onEditId,
  isInRestrictedMode,
  isDeadlineDatePassed,
  resetForm,
  project,
  clearFiled,
}) => {
  const [selectedLocations, setSelectedLocations] = useState(undefined);
  const [attachments, setAttachments] = useState(undefined);
  const [criteria, setCriteria] = useState(undefined);
  const [isInvitePartnersModalVisible, setIsInvitePartnersModalVisible] = useState(false);
  const [createdProjectId, setCreatedProjectId] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    if (stateValues?.selectedLocations && !selectedLocations) setSelectedLocations(stateValues.selectedLocations);
  }, [stateValues?.selectedLocations]);

  useEffect(() => {
    if (stateValues?.attachments && !attachments) setAttachments(stateValues.attachments);
  }, [stateValues?.attachments]);

  useEffect(() => {
    if (stateValues?.criteria && !criteria) setCriteria(stateValues.criteria);
  }, [stateValues?.criteria]);

  useEffect(() => {
    getCountries();
    getFocalPoints(officeId);
    getSectors();
  }, [getCountries, getFocalPoints, getSectors, officeId]);

  const onSubmit = async () => {
    try {
      const toBeSentValues = adaptProjectToBeSent(type, formValues, selectedLocations, attachments, criteria, agencyId, officeId, project, onEditId);
      const result = await saveProject(type, toBeSentValues, onEditId);
      if (onAfterSubmit) {
        resetForm();
        setSelectedLocations(undefined);
        setAttachments(undefined);
        setCriteria(undefined);
        setIsInvitePartnersModalVisible(false);
        setCreatedProjectId(undefined);
        onAfterSubmit();
      } else {
        switch (type) {
          case PROJECT_TYPES.OPEN:
            setIsInvitePartnersModalVisible(true);
            setCreatedProjectId(result.data.id);
            break;
          case PROJECT_TYPES.DIRECT:
            history.push(`/cfei/${type}/${result.data.eoi.id}/overview`);
            break;
          default:
            history.push(`/cfei/${type}/${result.data.id}/overview`);
            break;
        }
      }
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.projects.list.cmp.manage.project.modal.idx.saveProjectError",
        }),
      );
    }
  };

  const onCloseInvitePartnersModal = () => {
    setIsInvitePartnersModalVisible(false);
    history.push(`/cfei/${type}/${createdProjectId}/overview`);
  };

  const onSubmitInvitePartnersModal = async () => {
    try {
      const partners = {
        invited_partners: invitePartnersFormValues.invited_partners.map((item) => ({
          id: item.id,
          legal_name: item.legal_name,
          country_code: item.country_code,
        })),
      };
      await invitePartners(partners, createdProjectId);
      setIsInvitePartnersModalVisible(false);
      history.push(`/cfei/${type}/${createdProjectId}/overview`);
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.projects.list.cmp.manage.project.modal.idx.invitePartnersError",
        }),
      );
    }
  };

  const onClickMap = async (event) => {
    try {
      const updatedSelectedLocations = [...selectedLocations, await getLocationObject(event.location, countries, formValues?.countries)];
      setSelectedLocations(updatedSelectedLocations);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onClickDeleteLocation = (location) => {
    const updatedSelectedLocations = selectedLocations.filter((item) => item.id !== location.id);
    setSelectedLocations(updatedSelectedLocations);
  };

  const onChangeSector = (event) => {
    const newSectorsValue = event.target.value;
    const currentSelectedAreas = formValues?.areas ?? [];
    const filteredSectors = sectors.filter((sector) => newSectorsValue.find((formValuesSector) => sector.id.toString() === formValuesSector?.toString()));
    let filteredAreas = [];
    filteredSectors.forEach((sector) => {
      const sectorAreas = sector.specializations.map((area) => area.id);
      sectorAreas.forEach((area) => {
        if (currentSelectedAreas.includes(area)) {
          filteredAreas.push(area);
        }
      });
    });
    updateAreas(filteredAreas);
  };

  const onClickAddAttachment = () => {
    const newItemId = Math.max(...attachments.map((item) => item.uniqueId)) + 1;
    const updatedAttachments = [
      ...attachments,
      {
        uniqueId: newItemId,
        originalId: undefined,
      },
    ];
    setAttachments(updatedAttachments);
  };

  const onClickRemoveAttachment = (toRemove) => {
    const updatedAttachments = attachments.filter((item) => item.uniqueId !== toRemove.uniqueId);
    setAttachments(updatedAttachments);
    clearFiled(`attachment_${toRemove.uniqueId}`);
    clearFiled(`attachment_description_${toRemove.uniqueId}`);
  };

  const onClickAddCriteria = () => {
    const newItemId = Math.max(...criteria.map((item) => item.uniqueId)) + 1;
    const updatedCriteria = [
      ...criteria,
      {
        uniqueId: newItemId,
        originalId: undefined,
      },
    ];
    setCriteria(updatedCriteria);
  };

  const onClickRemoveCriteria = (toRemove) => {
    const updatedCriteria = criteria.filter((item) => item.uniqueId !== toRemove.uniqueId);
    setCriteria(updatedCriteria);
    clearFiled(`selection_criteria_${toRemove.uniqueId}`);
    clearFiled(`selection_criteria_description_${toRemove.uniqueId}`);
    if (formValues?.has_weighting?.toString() === "true") clearFiled(`selection_criteria_weight_${toRemove.uniqueId}`);
  };

  const onCloseModal = () => {
    resetForm();
    setSelectedLocations(undefined);
    setAttachments(undefined);
    setCriteria(undefined);
    setIsInvitePartnersModalVisible(false);
    setCreatedProjectId(undefined);
    onClose();
  };

  const adaptPartnersFunction = (partners) => adaptPartnersWithExtendedLabel(partners, countries);

  let filteredAreas = [];
  if (formValues.sectors?.length) {
    const filteredSectors = sectors.filter((sector) => formValues.sectors.find((formValuesSector) => sector.id.toString() === formValuesSector?.toString()));
    filteredAreas = adaptSpecializations(filteredSectors);
  }

  let currencies = adaptCurrencies();

  return (
    <>
      <ProjectModal
        type={type}
        isOpen={isOpen && !isInvitePartnersModalVisible}
        onClose={onCloseModal}
        countries={countries}
        formValues={formValues}
        focalPoints={focalPoints}
        sectors={sectors}
        isPopulationFieldVisible={isPopulationFieldVisible}
        concernTypes={concernTypes}
        selectionCriteria={selectionCriteria}
        selectedLocations={selectedLocations ?? []}
        attachments={attachments ?? []}
        criteria={criteria ?? []}
        onSubmit={onSubmit}
        onClickMap={onClickMap}
        onClickDeleteLocation={onClickDeleteLocation}
        onChangeSector={onChangeSector}
        filteredAreas={filteredAreas}
        onClickRemoveAttachment={onClickRemoveAttachment}
        onClickAddAttachment={onClickAddAttachment}
        onClickRemoveCriteria={onClickRemoveCriteria}
        onClickAddCriteria={onClickAddCriteria}
        title={getModalTitle(type)}
        informationText={getModalInformationText(type)}
        fetchPartnersFunction={getPartnersToInvite}
        justifications={justifications}
        initialValues={initialValues}
        stateValues={stateValues}
        isInRestrictedMode={isInRestrictedMode}
        isDeadlineDatePassed={isDeadlineDatePassed}
        currencies={currencies}
      />
      <InvitePartnersModal
        isOpen={isInvitePartnersModalVisible}
        onClose={onCloseInvitePartnersModal}
        onSubmit={onSubmitInvitePartnersModal}
        formValues={invitePartnersFormValues}
        fetchPartnersFunction={getPartnersToInvite}
        adaptPartnersFunction={adaptPartnersFunction}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  optionalLocations: state.partnerProfileConfig["countries-with-optional-location"],
  countries: adaptCountries(state?.countriesList?.data?.data ?? {}),
  formValues: state.form?.projectModalForm?.values ?? {},
  invitePartnersFormValues: state.form?.invitePartnersModalForm?.values ?? {},
  agencyId: state.session.agencyId,
  officeId: state.session.officeId,
  focalPoints: adaptFocalPoints(state.focalPointsList?.data?.data?.results ?? []),
  sectors: getSortedList(state?.sectorsList?.data?.data, "name"),
  isPopulationFieldVisible: state.session.agencyName === "UNHCR",
  concernTypes: getConcernTypes(),
  selectionCriteria: getSelectionCriteria(),
  justifications: adaptJustifications(state.partnerProfileConfig["direct-justifications"]),
});

const mapDispatchToProps = (dispatch) => ({
  saveProject: (type, values, id) => dispatch(getModalSaveFunction(type, id)(values, { id })),
  getCountries: () => dispatch(getCountriesList()),
  getFocalPoints: (officeId) => dispatch(getFocalPointsList({ focal: "true" }, { officeId })),
  getSectors: (params) => dispatch(getSectorsList(params)),
  updateAreas: (value) => dispatch(change("projectModalForm", "areas", value)),
  invitePartners: (partners, projectId) => dispatch(invitePartners(partners, { projectId })),
  getPartnersToInvite: (legal_name) => dispatch(getPartnersToInviteList({ is_hq: "False", legal_name, page_size: 150 })),
  resetForm: () => dispatch(reset("projectModalForm")),
  clearFiled: (fieldName) => dispatch(change("projectModalForm", fieldName, "")),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageProjectModal);
