/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { reset } from "redux-form";
import { withRouter, useHistory, useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import startOfToday from "date-fns/startOfToday";
import endOfDay from "date-fns/endOfDay";
import {
  getProjectDetails,
  getProjectApplicationDetails,
  getSectorsList,
  getOpenProjectApplicationsAllList,
  getOpenProjectApplicationsPreselectedRecommendedList,
  getCountriesList,
  getLocationsList,
  getOpenProjectRequestsList,
  getClarificationRequestsList,
  getClarificationAnswersList,
  addClarificationRequest,
  addClarificationAnswer,
  removeClarificationAnswer,
  updateProjectApplication,
  getPartnerProjectApplication,
  completeAssessment,
  deleteUploadedConceptNote,
  uploadConceptNote,
  getPartnerProfileDataDetails,
  deleteProject,
  cancelProject,
  invitePartners,
  getPartnersToInviteList,
  getAgencyReviewersList,
  updateReviewers,
  togglePin,
  deleteUCN,
} from "@local/actions";
import { clearLocalState } from "@local/legacy-reducers/conceptNote";
import { PROJECT_TYPES } from "@local/legacy-utils/constants";
import { PROJECT_STATUSES, projectDetailsNav, getConcernTypes, getPartnerTypes, APPLICATION_STATUSES, mapAgenciesIds } from "@local/utils/constants";
import { checkPermission, isRoleOffice, AGENCY_ROLES, AGENCY_PERMISSIONS, PARTNER_PERMISSIONS, COMMON_PERMISSIONS } from "@local/legacy-utils/permissions";
import Container from "./components/container";
import Overview from "./components/overview";
import OpenProjectResults from "./components/open-project-results";
import ProjectFeedback from "./components/feedback";
import OpenProjectRequests from "./components/open-project-requests";
import OpenProjectApplications from "./components/open-project-applications";
import OpenProjectPreselected from "./components/open-project-preselected";
import ProjectSubmission from "./components/project-submission";
import DirectProjectResponse from "./components/direct-project-response";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { adaptCountries, adaptFocalPoints, adaptPartnersWithExtendedLabel, adaptReviewersWithExtendedEmailLabel } from "@local/utils/adapters";
import ManageProjectModal from "@local/pages/projects-list/components/manage-project-modal";
import InvitePartnersModal from "@local/pages/projects-list/components/manage-project-modal/components/invite-partners-modal";
import { adaptEditProjectInitialFormValues } from "./utils/adapters";
import ConfirmModal from "@local/components/confirm-modal";
import ManageReviewersModal from "./components/manage-reviewers-modal";
import { getSortedList } from "@local/utils";

// POST_REFACTORING_TODO: In concept note submission tab, if partner submitted a concept note, a green icon should be displayed near to the tab title
// POST_REFACTORING_TODO: Check that "Requests for additional Information/Clarifications" upload button is working

const getSelectedTabIndex = (tabs, location, id, type) => {
  const selectedTabIndex = tabs.findIndex((tab) => location.pathname.startsWith(`/cfei/${type}/${id}/${tab.path}`));
  if (selectedTabIndex === -1) return 0;
  return selectedTabIndex;
};

const ProjectDetails = ({
  tabs,
  project,
  unsolicitedProject,
  role,
  type,
  getProjectDetails,
  getProjectApplicationDetails,
  loadProjectApplication,
  agencyId,
  agency,
  isCompleted,
  conceptNote,
  cn_template,
  displayGoal,
  sectors,
  agencies,
  partnerId,
  countries,
  concernTypes,
  partnerTypes,
  getSectors,
  getCountries,
  getLocations,
  getOpenProjectApplications,
  currentStatus,
  projectStatus,
  isProjectPublished,
  getOpenProjectRequests,
  getClarificationRequests,
  getClarificationAnswers,
  isClarificationDeadlinePassed,
  requestsLoading,
  requestCount,
  addRequest,
  addAnswer,
  deleteAnswer,
  addRequestFormValues,
  addClarificationAnswerFormValues,
  isAddSummaryAllowed,
  updateProjectApplication,
  partnerProjectApplication,
  getPreselectedApplications,
  isFinishedReview,
  isCompletedAssessment,
  isReviewer,
  isDeadlinePassed,
  isSend,
  selectionCriteria,
  completeAssessment,
  isCreator,
  isFocalPoint,
  isAdvEd,
  isAdvEdPlus,
  isAdvEdSecretariat,
  isPAM,
  isMFT,
  isFinalizeAllowed,
  hasSendPermission,
  hasPublishPermission,
  hasWinners,
  hasPermissionToAddRequest,
  hasManageDraftPermission,
  hasInviteSentPermission,
  hasEditSentPermission,
  hasInvitePublishPermission,
  hasCancelPublishPermission,
  hasManageReviewersPermission,
  hasEditPublishedDatesPermission,
  isPublishPermissionAllowed,
  hasRecommendedPartner,
  hasEditDraftPermission,
  hasDeleteDraftPermission,
  hasEditPublishedPermission,
  isEditPublishedPermission,
  hasCancelPermission,
  hasDeletePermission,
  hasEditPermission,
  hasDsrConvertPermission,
  hasSubmitPermission,
  hasPinPermission,
  hasUploadCnPermission,
  cfeiConverted,
  partnerVerified,
  pinned,
  isPartnerVerified,
  isPartnerProfileComplete,
  isHq,
  isProfileComplete,
  isConceptNoteUploaded,
  uploadConceptNote,
  deleteConceptNote,
  deadlineDate,
  submitDate,
  applicationId,
  conceptNoteSubmissionFormValues,
  updatedDate,
  resetConceptNoteSubmissionForm,
  resetAddClarificationForm,
  getPartnerProfileData,
  partnerProfile,
  officeId,
  deleteProject,
  cancelProject,
  getPartnersToInvite,
  invitePartners,
  invitePartnersFormValues,
  updateReviewers,
  manageReviewersFormValues,
  getReviewers,
  togglePin,
  hasDSRAnswerPermission,
  deleteUCN,
  isApplicationDeadlinePassed,
}) => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [locations, setLocations] = useState([]);
  const [isUploadFileModalVisible, setIsUploadFileModalVisible] = useState(false);
  const [isRemoveFileModalVisible, setIsRemoveFileModalVisible] = useState(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [isAddClarificationRequestModalVisible, setIsClarificationRequestModalVisible] = useState(false);
  const [isCompletingAssessmentVisible, setIseCompletingAssessmentVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isEditModalRestricted, setIsEditModalRestricted] = useState(false);
  const [isDeleteOpenProjectModalVisible, setIsDeleteOpenProjectModalVisible] = useState(false);
  const [isDeleteDirectSelectionProjectModalVisible, setIsDeleteDirectSelectionProjectModalVisible] = useState(false);
  const [isDeleteUnsolicitedConceptNoteModalVisible, setIsDeleteUnsolicitedConceptNoteModalVisible] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isInvitePartnersModalVisible, setIsInvitePartnersModalVisible] = useState(false);
  const [isManageReviewersModalVisible, setIsManageReviewersModalVisible] = useState(false);
  const [editModalInitialFormValues, setEditModalInitialFormValues] = useState(undefined);
  const [shouldRefreshApplications, setShouldRefreshApplication] = useState(false);
  const [shouldRefreshPreselected, setShouldRefreshPreselected] = useState(false);
  const [currentToDeleteClarificationAnswer, setCurrentToDeleteClarificationAnswer] = useState(undefined);

  useEffect(() => {
    getSectors();
    getCountries();
    refresh();
    loadProjectApplication();
  }, []);

  const refresh = () => {
    if (type === PROJECT_TYPES.UNSOLICITED) getProjectApplicationDetails().catch(() => undefined);
    else getProjectDetails();
  };

  const onClickOpenPreviewModal = () => setIsPreviewModalVisible(true);

  const onClickClosePreviewModal = () => setIsPreviewModalVisible(false);

  const onClickOpenAddClarificationRequestModal = () => setIsClarificationRequestModalVisible(true);

  const onClickCloseAddClarificationRequestModal = () => {
    setIsClarificationRequestModalVisible(false);
    resetAddClarificationForm();
  };

  const onClickOpenUploadFileModal = () => setIsUploadFileModalVisible(true);

  const onClickCloseUploadFileModal = () => setIsUploadFileModalVisible(false);

  const onClickOpenRemoveFileModal = (toDeleteAnswer) => {
    setCurrentToDeleteClarificationAnswer(toDeleteAnswer);
    setIsRemoveFileModalVisible(true);
  };

  const onClickCloseRemoveFileModal = () => setIsRemoveFileModalVisible(false);

  const onClickOpenCompletingAssessmentModal = () => setIseCompletingAssessmentVisible(true);

  const onClickCloseCompletingAssessmentModal = () => setIseCompletingAssessmentVisible(false);

  const handleClickOpenAddRequestMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAddRequestMenu = () => {
    setAnchorEl(null);
  };

  const onClickAddClarificationRequest = async () => {
    try {
      await addRequest(id, addRequestFormValues);
      await getClarificationRequests();
      onClickCloseAddClarificationRequestModal();
      resetAddClarificationForm();
    } catch (error) {
      toast.error(formatMessage({ id: "pages.project.details.idx.error" }));
    }
  };

  const onClickAddClarificationAnswer = async () => {
    try {
      const adaptedValues = {
        title: addClarificationAnswerFormValues?.title,
        file: addClarificationAnswerFormValues?.file?.id,
      };
      await addAnswer(id, adaptedValues);
      await getClarificationAnswers();
      onClickCloseUploadFileModal();
    } catch (error) {
      toast.error(formatMessage({ id: "pages.project.details.idx.errorOne" }));
    }
  };

  const onClickDeleteClarificationAnswer = async () => {
    try {
      await deleteAnswer(currentToDeleteClarificationAnswer);
      await getClarificationAnswers();
      onClickCloseRemoveFileModal();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.project.details.idx.deleteClarificationAnswerModalError",
        }),
      );
    }
  };

  const onClickRecommendApplication = async (applicationId, status) => {
    await updateProjectApplication(applicationId, status);
    await getProjectDetails();
    if (status === APPLICATION_STATUSES.PRE) history.push(`/cfei/open/${id}/results`);
    else history.push(history.getCurrentLocation()); // POST_REFACTORING_TODO: Before: history.push(R.assoc("hash", null, history.getCurrentLocation()))
  };

  const onClickCompleteAssessment = async () => {
    await completeAssessment();
    await getProjectDetails();
    await getPreselectedApplications();
    onClickCloseCompletingAssessmentModal();
  };

  const onClickEdit = () => {
    const currentProject = type === PROJECT_TYPES.UNSOLICITED ? unsolicitedProject : project;
    const { status, is_published } = currentProject;
    if (hasEditPermission && type === PROJECT_TYPES.UNSOLICITED) {
      setIsEditModalRestricted(false);
      setIsEditModalVisible(true);
      setEditModalInitialFormValues(adaptEditProjectInitialFormValues(currentProject, type, sectors, countries));
    } else if (((hasManageDraftPermission && isCreator && status === PROJECT_STATUSES.DRA) || (hasEditSentPermission && isFocalPoint)) && !is_published && type !== PROJECT_TYPES.UNSOLICITED) {
      setIsEditModalRestricted(false);
      setIsEditModalVisible(true);
      setEditModalInitialFormValues(adaptEditProjectInitialFormValues(currentProject, type, sectors, countries));
    } else if (isPublishPermissionAllowed(hasEditPublishedDatesPermission) && is_published && type !== PROJECT_TYPES.UNSOLICITED) {
      setIsEditModalRestricted(true);
      setIsEditModalVisible(true);
      setEditModalInitialFormValues(adaptEditProjectInitialFormValues(currentProject, type, sectors, countries));
    }
  };

  const onClickDeleteOpenProject = () => setIsDeleteOpenProjectModalVisible(true);

  const onClickDeleteDirectSelectionProject = () => setIsDeleteDirectSelectionProjectModalVisible(true);

  const onClickDeleteUnsolicitedConceptNote = () => setIsDeleteUnsolicitedConceptNoteModalVisible(true);

  const onClickCancel = () => setIsCancelModalVisible(true);

  const onClickInvitePartners = () => setIsInvitePartnersModalVisible(true);

  const onClickManageReviewers = () => setIsManageReviewersModalVisible(true);

  const onClickSubmitPublish = () => {
    setIsEditModalVisible(false);
    setIsEditModalRestricted(false);
    refresh();
  };

  const onClickCloseEditModal = () => {
    setIsEditModalRestricted(false);
    setIsEditModalVisible(false);
  };

  const onClickCloseDeleteOpenProjectModal = () => setIsDeleteOpenProjectModalVisible(false);

  const onClickCloseDeleteDirectSelectionProjectModal = () => setIsDeleteDirectSelectionProjectModalVisible(false);

  const onClickCloseDeleteUnsolicitedConceptNoteModal = () => setIsDeleteUnsolicitedConceptNoteModalVisible(false);

  const onClickCloseCancelModal = () => setIsCancelModalVisible(false);

  const onClickCloseInvitePartnersModal = () => setIsInvitePartnersModalVisible(false);

  const onClickCloseManageReviewersModal = () => setIsManageReviewersModalVisible(false);

  const onClickSubmitEditModal = () => {
    setIsEditModalVisible(false);
    refresh();
  };

  const onClickSubmitDeleteOpenProjectModal = async () => {
    try {
      await deleteProject();
      setIsDeleteOpenProjectModalVisible(false);
      history.push("/cfei/open");
    } catch (error) {
      toast.error(formatMessage({ id: "pages.project.details.idx.unableToSaveData" }));
    }
  };

  const onClickSubmitDeleteDirectSelectionProjectModal = async () => {
    try {
      await deleteProject();
      setIsDeleteDirectSelectionProjectModalVisible(false);
      history.push("/cfei/direct");
    } catch (error) {
      toast.error(formatMessage({ id: "pages.project.details.idx.unableToSaveData" }));
    }
  };

  const onClickSubmitDeleteUnsolicitedConceptNoteModal = async () => {
    try {
      await deleteUCN();
      setIsDeleteUnsolicitedConceptNoteModalVisible(false);
      history.push("/cfei/unsolicited");
    } catch (error) {
      toast.error(formatMessage({ id: "pages.project.details.idx.unableToSaveData" }));
    }
  };

  const onClickSubmitCancelModal = async () => {
    try {
      await cancelProject();
      setIsCancelModalVisible(false);
      history.push("/cfei/open");
    } catch (error) {
      toast.error(formatMessage({ id: "pages.project.details.idx.unableToSaveData" }));
    }
  };

  const onClickSubmitInvitePartnersModal = async () => {
    try {
      await invitePartners(invitePartnersFormValues);
      setIsInvitePartnersModalVisible(false);
      refresh();
    } catch (error) {
      toast.error(formatMessage({ id: "pages.project.details.idx.unableToSaveData" }));
    }
  };

  const onClickTogglePin = async () => {
    try {
      await togglePin(!project?.is_pinned);
      refresh();
    } catch (error) {
      toast.error(formatMessage({ id: "pages.project.details.idx.unableToSaveData" }));
    }
  };

  const onClickSubmitManageReviewersModal = async () => {
    try {
      await updateReviewers({
        reviewers: manageReviewersFormValues?.reviewers?.map((item) => item.id),
      });
      setIsManageReviewersModalVisible(false);
      refresh();
    } catch (error) {
      toast.error(formatMessage({ id: "pages.project.details.idx.unableToSaveData" }));
    }
  };

  const updateProjectApplicationAndRefresh = async (applicationId, status) => {
    try {
      await updateProjectApplication(applicationId, status);
      setShouldRefreshApplication(true);
      setShouldRefreshPreselected(true);
    } catch (error) {
      toast.error(formatMessage({ id: "pages.project.details.idx.unableToSaveData" }));
    }
  };

  const afterRefreshApplications = () => {
    setShouldRefreshApplication(false);
    setShouldRefreshPreselected(false);
  };

  const afterRefreshPreselected = () => {
    setShouldRefreshApplication(false);
    setShouldRefreshPreselected(false);
  };

  const filterTabs = () => {
    let tabsToRender = tabs.filter((tab) => tab.roles?.includes(role) && tab.types?.includes(type) && tab.isVisible);
    return tabsToRender;
  };

  const getReviewersByOffice = (name) => getReviewers(officeId, name);

  const adaptPartnersFunction = (partners) => adaptPartnersWithExtendedLabel(partners, countries);

  const adaptReviewersFunction = (reviewers) => adaptReviewersWithExtendedEmailLabel(reviewers);

  const renderedTabs = filterTabs();

  const onChangeTab = (event, index) => history.push(`/cfei/${type}/${id}/${renderedTabs[index].path}`);

  const selectedTabIndex = getSelectedTabIndex(renderedTabs, location, id, type);

  let content = undefined;
  const selectedTab = renderedTabs[selectedTabIndex];

  switch (selectedTab.path) {
    case "overview":
      content = (
        <Overview
          project={type === PROJECT_TYPES.UNSOLICITED ? unsolicitedProject : project}
          isCompleted={isCompleted}
          agency={agency}
          conceptNote={conceptNote}
          cn_template={cn_template}
          partnerId={partnerId}
          role={role}
          displayGoal={displayGoal}
          agencyId={agencyId}
          sectors={sectors}
          agencies={agencies}
          currentStatus={currentStatus}
          projectStatus={projectStatus}
          isProjectPublished={isProjectPublished}
          isUploadFileModalVisible={isUploadFileModalVisible}
          isRemoveFileModalVisible={isRemoveFileModalVisible}
          isPreviewModalVisible={isPreviewModalVisible}
          isAddClarificationRequestModalVisible={isAddClarificationRequestModalVisible}
          onClickOpenPreviewModal={onClickOpenPreviewModal}
          onClickClosePreviewModal={onClickClosePreviewModal}
          getClarificationRequests={getClarificationRequests}
          isClarificationDeadlinePassed={isClarificationDeadlinePassed}
          requestsLoading={requestsLoading}
          requestCount={requestCount}
          hasPermissionToAddRequest={hasPermissionToAddRequest}
          handleClickOpenAddRequestMenu={handleClickOpenAddRequestMenu}
          handleCloseAddRequestMenu={handleCloseAddRequestMenu}
          onClickOpenAddClarificationRequestModal={onClickOpenAddClarificationRequestModal}
          onClickCloseAddClarificationRequestModal={onClickCloseAddClarificationRequestModal}
          openAddRequestMenu={(anchorEl ?? undefined) !== undefined}
          anchorEl={anchorEl}
          onClickAddClarificationRequest={onClickAddClarificationRequest}
          onClickOpenUploadFileModal={onClickOpenUploadFileModal}
          onClickCloseUploadFileModal={onClickCloseUploadFileModal}
          onClickOpenRemoveFileModal={onClickOpenRemoveFileModal}
          onClickCloseRemoveFileModal={onClickCloseRemoveFileModal}
          onClickAddClarificationAnswer={onClickAddClarificationAnswer}
          onClickDeleteClarificationAnswer={onClickDeleteClarificationAnswer}
          getClarificationAnswers={getClarificationAnswers}
          isApplicationDeadlinePassed={isApplicationDeadlinePassed}
          isCreator={isCreator}
          isFocalPoint={isFocalPoint}
        />
      );
      break;
    case "results":
      content = <OpenProjectResults projectId={id} project={project} isAddSummaryAllowed={isAddSummaryAllowed} hasRecommendedPartner={hasRecommendedPartner} isSend={isSend} applicationId={applicationId} />;
      break;
    case "feedback":
      content = <ProjectFeedback allowedToAddDirectFeedback={false} isCompleted={isCompleted} isCreator={isCreator} isFocalPoint={isFocalPoint} role={role} />;
      break;
    case "requests":
      content = <OpenProjectRequests fetchFunction={getOpenProjectRequests} projectId={id} />;
      break;
    case "applications":
      content = (
        <OpenProjectApplications
          fetchFunction={getOpenProjectApplications}
          countries={countries}
          getLocations={getLocations}
          locations={locations}
          setLocations={setLocations}
          sectors={sectors}
          concernTypes={concernTypes}
          partnerTypes={partnerTypes}
          updateProjectApplication={updateProjectApplicationAndRefresh}
          project={type === PROJECT_TYPES.UNSOLICITED ? unsolicitedProject : project}
          shouldRefresh={shouldRefreshApplications || shouldRefreshPreselected}
          afterRefresh={afterRefreshApplications}
          isCompleted={isCompleted}
          isFocalPoint={isFocalPoint}
          isCreator={isCreator}
          isApplicationDeadlinePassed={isApplicationDeadlinePassed}
          isReviewer={isReviewer}
        />
      );
      break;
    case "preselected":
      content = (
        <OpenProjectPreselected
          fetchFunction={getPreselectedApplications}
          isFinishedReview={isFinishedReview}
          isCompletedAssessment={isCompletedAssessment}
          isReviewer={isReviewer}
          isDeadlinePassed={isDeadlinePassed}
          isCreator={isCreator}
          isSend={isSend}
          projectId={id}
          isFocalPoint={isFocalPoint}
          updateProjectApplication={updateProjectApplicationAndRefresh}
          selectionCriteria={selectionCriteria}
          isCompletingAssessmentVisible={isCompletingAssessmentVisible}
          onClickRecommendApplication={onClickRecommendApplication}
          onClickCloseCompletingAssessmentModal={onClickCloseCompletingAssessmentModal}
          onClickOpenCompletingAssessmentModal={onClickOpenCompletingAssessmentModal}
          onClickCompleteAssessment={onClickCompleteAssessment}
          shouldRefresh={shouldRefreshApplications || shouldRefreshPreselected}
          afterRefresh={afterRefreshPreselected}
          isApplicationDeadlinePassed={isApplicationDeadlinePassed}
          type={type}
        />
      );
      break;
    case "submission":
      content = (
        <ProjectSubmission
          partnerId={partnerId}
          isDeadlinePassed={isDeadlinePassed}
          hasUploadCnPermission={hasUploadCnPermission}
          isHq={isHq}
          isProfileComplete={isProfileComplete}
          isConceptNoteUploaded={isConceptNoteUploaded}
          uploadConceptNote={uploadConceptNote}
          deleteConceptNote={deleteConceptNote}
          deadlineDate={deadlineDate}
          submitDate={submitDate}
          applicationId={applicationId}
          conceptNoteSubmissionFormValues={conceptNoteSubmissionFormValues}
          updatedDate={updatedDate}
          resetConceptNoteSubmissionForm={resetConceptNoteSubmissionForm}
          partnerProjectApplication={partnerProjectApplication}
          loadProjectApplication={loadProjectApplication}
          getPartnerProfileData={getPartnerProfileData}
          partnerProfile={partnerProfile}
          countries={countries}
        />
      );
      break;
    case "response":
      content = <DirectProjectResponse role={role} hasDSRAnswerPermission={hasDSRAnswerPermission} project={project} projectId={id} />;
      break;
    default:
      content = undefined;
      break;
  }

  const deadline = endOfDay(new Date(project?.deadline_date));

  const today = startOfToday();

  const isDeadlineDatePassed = project?.deadline_date ? deadline < today : undefined;

  return (
    <>
      <Container
        project={type === PROJECT_TYPES.UNSOLICITED ? unsolicitedProject : project}
        tabs={renderedTabs}
        selectedTab={selectedTabIndex}
        onChangeTab={onChangeTab}
        type={type}
        isProjectPublished={isProjectPublished}
        isCompleted={isCompleted}
        isCreator={isCreator}
        isFocalPoint={isFocalPoint}
        isAdvEd={isAdvEd}
        isAdvEdPlus={isAdvEdPlus}
        isAdvEdSecretariat={isAdvEdSecretariat}
        isPAM={isPAM}
        isMFT={isMFT}
        isFinalizeAllowed={isFinalizeAllowed}
        hasSendPermission={hasSendPermission}
        hasPublishPermission={hasPublishPermission}
        isDeadlinePassed={isDeadlinePassed}
        isClarificationDeadlinePassed={isClarificationDeadlinePassed}
        projectStatus={projectStatus}
        hasWinners={hasWinners}
        isSend={isSend}
        hasManageDraftPermission={hasManageDraftPermission}
        hasInviteSentPermission={hasInviteSentPermission}
        hasEditSentPermission={hasEditSentPermission}
        hasInvitePublishPermission={hasInvitePublishPermission}
        hasCancelPublishPermission={hasCancelPublishPermission}
        hasManageReviewersPermission={hasManageReviewersPermission}
        hasEditPublishedDatesPermission={hasEditPublishedDatesPermission}
        isPublishPermissionAllowed={isPublishPermissionAllowed}
        hasRecommendedPartner={hasRecommendedPartner}
        hasEditDraftPermission={hasEditDraftPermission}
        hasDeleteDraftPermission={hasDeleteDraftPermission}
        hasEditPublishedPermission={hasEditPublishedPermission}
        isEditPublishedPermission={isEditPublishedPermission}
        hasCancelPermission={hasCancelPermission}
        hasDeletePermission={hasDeletePermission}
        hasEditPermission={hasEditPermission}
        hasDsrConvertPermission={hasDsrConvertPermission}
        hasSubmitPermission={hasSubmitPermission}
        role={role}
        cfeiConverted={cfeiConverted}
        partnerVerified={partnerVerified}
        hasPinPermission={hasPinPermission}
        pinned={pinned}
        isPartnerVerified={isPartnerVerified}
        isPartnerProfileComplete={isPartnerProfileComplete}
        onClickEdit={onClickEdit}
        onClickDeleteOpenProject={onClickDeleteOpenProject}
        onClickDeleteDirectSelectionProject={onClickDeleteDirectSelectionProject}
        onClickDeleteUnsolicitedConceptNote={onClickDeleteUnsolicitedConceptNote}
        onClickCancel={onClickCancel}
        onClickInvitePartners={onClickInvitePartners}
        onClickManageReviewers={onClickManageReviewers}
        onAfterSubmitPublish={onClickSubmitPublish}
        onClickTogglePin={onClickTogglePin}
        isPinned={project?.is_pinned ?? false}
        refreshProjectFunction={refresh}
      >
        {content}
      </Container>
      {isEditModalVisible && editModalInitialFormValues ? (
        <ManageProjectModal
          type={type}
          isOpen
          onClose={onClickCloseEditModal}
          onAfterSubmit={onClickSubmitEditModal}
          initialValues={editModalInitialFormValues?.formValues}
          stateValues={editModalInitialFormValues?.stateValues}
          onEditId={type === PROJECT_TYPES.UNSOLICITED ? unsolicitedProject?.id : project?.id}
          isInRestrictedMode={isEditModalRestricted}
          isDeadlineDatePassed={isDeadlineDatePassed}
          project={type === PROJECT_TYPES.UNSOLICITED ? unsolicitedProject : project}
        />
      ) : undefined}
      <ConfirmModal isOpen={isDeleteOpenProjectModalVisible} onSubmit={onClickSubmitDeleteOpenProjectModal} onClose={onClickCloseDeleteOpenProjectModal} message={formatMessage({ id: "pages.project.details.idx.info" })} type="warning" />
      <ConfirmModal
        isOpen={isDeleteDirectSelectionProjectModalVisible}
        onSubmit={onClickSubmitDeleteDirectSelectionProjectModal}
        onClose={onClickCloseDeleteDirectSelectionProjectModal}
        message={formatMessage({ id: "pages.project.details.idx.infoOne" })}
        type="warning"
      />
      <ConfirmModal
        isOpen={isDeleteUnsolicitedConceptNoteModalVisible}
        onSubmit={onClickSubmitDeleteUnsolicitedConceptNoteModal}
        onClose={onClickCloseDeleteUnsolicitedConceptNoteModal}
        message={formatMessage({ id: "pages.project.details.idx.infoTwo" })}
        type="warning"
      />
      <ConfirmModal isOpen={isCancelModalVisible} onSubmit={onClickSubmitCancelModal} onClose={onClickCloseCancelModal} message={formatMessage({ id: "pages.project.details.idx.infoThree" })} type="warning" />
      {isInvitePartnersModalVisible && project?.invited_partners ? (
        <InvitePartnersModal
          isOpen={isInvitePartnersModalVisible}
          onClose={onClickCloseInvitePartnersModal}
          onSubmit={onClickSubmitInvitePartnersModal}
          initialValues={{
            invited_partners: adaptPartnersFunction(project?.invited_partners),
          }}
          formValues={invitePartnersFormValues}
          fetchPartnersFunction={getPartnersToInvite}
          adaptPartnersFunction={adaptPartnersFunction}
        />
      ) : undefined}
      {isManageReviewersModalVisible && project?.reviewers_detail ? (
        <ManageReviewersModal
          isOpen={isManageReviewersModalVisible}
          onClose={onClickCloseManageReviewersModal}
          onSubmit={onClickSubmitManageReviewersModal}
          initialValues={{
            reviewers: adaptReviewersFunction(project?.reviewers_detail),
          }}
          initialReviewers={project?.reviewers_detail}
          formValues={manageReviewersFormValues}
          fetchReviewersFunction={getReviewersByOffice}
          adaptReviewersFunction={adaptReviewersFunction}
        />
      ) : undefined}
    </>
  );
};

ProjectDetails.propTypes = {};

const mapStateToProps = (state, ownProps) => {
  const isAdvEd = isRoleOffice(AGENCY_ROLES.EDITOR_ADVANCED, state);
  const isMFT = isRoleOffice(AGENCY_ROLES.MFT_USER, state);
  const isPAM = isRoleOffice(AGENCY_ROLES.PAM_USER, state);
  const isBasEd = isRoleOffice(AGENCY_ROLES.EDITOR_BASIC, state);
  const isReviewer = state?.projectDetails?.data?.data?.reviewers?.includes(state?.session?.userId);
  const isCreator = state?.projectDetails?.data?.data?.created_by === state?.session?.userId;
  const isFocalPoint = state?.projectDetails?.data?.data?.focal_points?.includes(state?.session?.userId);
  const isSend = state?.projectDetails?.data?.data?.sent_for_decision;
  const isAdvEdPlus = isRoleOffice(AGENCY_ROLES.EDITOR_ADVANCED_PLUS, state);
  const isAdvEdSecretariat = isRoleOffice(AGENCY_ROLES.EDITOR_ADVANCED_SECRETARIAT, state);
  const isHqEd = isRoleOffice(AGENCY_ROLES.HQ_EDITOR, state);
  const agencyName = state.session.agencyName;

  const hasPermissionToViewPreApplications = (hasActionPermission) =>
    (hasActionPermission && (isAdvEd || isAdvEdPlus || isAdvEdSecretariat) && (isCreator || isFocalPoint || isReviewer)) ||
    (hasActionPermission && isBasEd && (isCreator || isReviewer)) ||
    (hasActionPermission && isMFT && isFocalPoint) ||
    (hasActionPermission && isMFT && isReviewer) ||
    (hasActionPermission && isPAM && isReviewer) ||
    (hasActionPermission && isPAM && isCreator) ||
    // Allow HQ editor from UNHCR to view adv ed tabs UNPP-878
    (hasActionPermission && isHqEd && agencyName === "UNHCR");

  const hasPermissionToViewApplications = (hasActionPermission) =>
    (hasActionPermission && (isAdvEd || isAdvEdPlus || isAdvEdSecretariat)) ||
    (hasActionPermission && isBasEd) ||
    (hasActionPermission && isMFT && isFocalPoint) ||
    (hasActionPermission && isPAM && isCreator) ||
    // Allow HQ editor from UNHCR to view adv ed tabs UNPP-878
    (hasActionPermission && isHqEd && agencyName === "UNHCR");

  const isActionAllowed = (hasActionPermission) =>
    (hasActionPermission && (isAdvEd || isAdvEdPlus || isAdvEdSecretariat) && (isCreator || isFocalPoint)) || (hasActionPermission && isBasEd && isCreator && !isSend) || (hasActionPermission && isMFT && isFocalPoint) || (hasActionPermission && isPAM && isCreator);

  const isFinalizeAllowed = (hasActionPermission) =>
    (hasActionPermission && (isAdvEd || isAdvEdPlus || isAdvEdSecretariat) && (isCreator || isFocalPoint)) || (hasActionPermission && isBasEd && isCreator) || (hasActionPermission && isMFT && isFocalPoint) || (hasActionPermission && isPAM && isCreator);

  const isPublishPermissionAllowed = (hasActionPermission) =>
    (hasActionPermission && (isAdvEd || isAdvEdPlus || isAdvEdSecretariat) && (isCreator || isFocalPoint)) || (hasActionPermission && isBasEd && isCreator) || (hasActionPermission && isPAM && isCreator) || (hasActionPermission && isMFT && (isCreator || isFocalPoint));

  return {
    focalPoints: adaptFocalPoints(state.focalPointsList?.data?.data?.results ?? []),
    project: state?.projectDetails?.data?.data,
    unsolicitedProject: state?.unProjectApplicationDetails?.data?.data,
    location: ownProps?.location?.pathname,
    tabs: projectDetailsNav(state, ownProps?.match?.params?.type, state?.session?.role, hasPermissionToViewApplications, hasPermissionToViewPreApplications),
    role: state?.session?.role,
    type: ownProps?.match?.params?.type,
    loading: state?.projectDetails?.loading,
    hasReviewPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_REVIEW_APPLICATIONS, state),
    hasViewApplicationsPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_VIEW_APPLICATIONS, state),
    hasViewAllPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_FINALIZED_VIEW_ALL_INFO, state),
    hasViewWinnerPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_FINALIZED_VIEW_WINNER_AND_CN, state),
    hasRequestsViewPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_PUBLISHED_VIEW_AND_ANSWER_CLARIFICATION_QUESTIONS, state),
    hasSendPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_DRAFT_SEND_TO_FOCAL_POINT_TO_PUBLISH, state),
    hasPublishPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_PUBLISH, state) || checkPermission(AGENCY_PERMISSIONS.CFEI_SENT_PUBLISH, state),
    hasManageDraftPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_DRAFT_MANAGE, state),
    hasInviteSentPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_SENT_INVITE_CSO, state),
    hasEditSentPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_SENT_EDIT, state),
    hasInvitePublishPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_PUBLISHED_INVITE_CSO, state),
    hasCancelPublishPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_PUBLISHED_CANCEL, state),
    hasManageReviewersPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_MANAGE_REVIEWERS, state),
    hasEditPublishedDatesPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_PUBLISHED_EDIT_DATES, state),
    hasEditDraftPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_DIRECT_EDIT_DRAFT, state),
    hasDeleteDraftPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_DIRECT_DELETE_DRAFT, state),
    hasEditPublishedPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_DIRECT_EDIT_PUBLISHED, state),
    hasCancelPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_DIRECT_CANCEL, state),
    hasDeletePermission: checkPermission(PARTNER_PERMISSIONS.UCN_DELETE, state),
    hasEditPermission: checkPermission(PARTNER_PERMISSIONS.UCN_EDIT, state),
    hasDsrConvertPermission: checkPermission(AGENCY_PERMISSIONS.UCN_CONVERT_TO_DSR, state),
    hasSubmitPermission: checkPermission(PARTNER_PERMISSIONS.UCN_SUBMIT, state),
    hasPinPermission: checkPermission(PARTNER_PERMISSIONS.CFEI_PINNING, state),
    hasUploadCnPermission: checkPermission(PARTNER_PERMISSIONS.CFEI_SUBMIT_CONCEPT_NOTE, state),
    hasDSRAnswerPermission: checkPermission(PARTNER_PERMISSIONS.DSR_ANSWER, state),
    cnFile: state.conceptNote.cnFile,
    error: state?.projectDetails?.error,
    agencyId: state?.session?.agencyId,
    agency: state?.projectDetails?.data?.data?.agency,
    conceptNote: state?.unProjectApplicationDetails?.data?.data?.cn,
    cn_template: state?.projectDetails?.data?.data?.cn_template,
    displayGoal: state?.projectDetails?.data?.data?.goal,
    sectors: getSortedList(state?.sectorsList?.data?.data, "name"),
    agencies: mapAgenciesIds(state?.agenciesList?.data?.data?.results) ?? [],
    isCompleted: state?.projectDetails?.data?.data?.is_completed || state?.projectDetails?.data?.data?.completed_reason,
    partnerId: state?.session?.partnerId,
    countries: adaptCountries(state?.countriesList?.data?.data ?? {}),
    concernTypes: getConcernTypes(),
    partnerTypes: getPartnerTypes(),
    currentStatus: state?.projectDetails?.data?.data?.direct_selected_partners?.map((item) => item?.application_status)?.at(0) ?? undefined,
    projectStatus: state?.projectDetails?.data?.data?.status,
    hasWinners: state?.projectDetails?.data?.data?.contains_partner_accepted,
    isProjectPublished: state?.projectDetails?.data?.data?.is_published,
    requestsLoading: state?.addClarificationRequest?.status?.loading,
    isClarificationDeadlinePassed: state?.projectDetails?.data?.data?.clarification_request_deadline_passed,
    isApplicationDeadlinePassed: state?.projectDetails?.data?.data?.deadline_passed,
    hasPermissionToAddRequest: checkPermission(PARTNER_PERMISSIONS.CFEI_SEND_CLARIFICATION_REQUEST, state),
    requestCount: state?.clarificationRequestsList?.data?.data?.count,
    addRequestFormValues: state?.form?.addClarificationForm?.values,
    completeCfeiFormValues: state?.form?.completeCfeiForm?.values,
    addClarificationAnswerFormValues: state?.form?.addClarificationAnswerForm?.values,
    isAddSummaryAllowed: isActionAllowed(checkPermission(AGENCY_PERMISSIONS.CFEI_ADD_REVIEW_SUMMARY, state)),
    isEditPublishedPermission: isActionAllowed(checkPermission(AGENCY_PERMISSIONS.CFEI_DIRECT_EDIT_PUBLISHED, state)),
    partnerProjectApplication: state?.partnerProjectApplication?.data?.data,
    isFinishedReview: state?.projectDetails?.data?.data?.current_user_finished_reviews,
    isCompletedAssessment: state?.projectDetails?.data?.data?.current_user_marked_reviews_completed,
    isReviewer: isReviewer,
    isDeadlinePassed: state?.projectDetails?.data?.data?.deadline_passed,
    isSend: state?.projectDetails?.data?.data?.sent_for_decision,
    hasRecommendedPartner: state?.projectDetails?.data?.data?.contains_recommended_applications,
    cfeiConverted: state?.unProjectApplicationDetails?.data?.data?.eoi_converted,
    partnerVerified: state?.unProjectApplicationDetails?.data?.data?.partner?.partner_additional?.is_verified,
    pinned: state?.projectDetails?.data?.data?.is_pinned,
    deadlineDate: state?.projectDetails?.data?.data?.deadline_date,
    selectionCriteria: state?.selectionCriteria,
    isCreator,
    isFocalPoint,
    isAdvEd: isAdvEd,
    isPAM: isPAM,
    isMFT: isMFT,
    isAdvEdPlus: isAdvEdPlus,
    isAdvEdSecretariat: isAdvEdSecretariat,
    isFinalizeAllowed: isFinalizeAllowed(checkPermission(COMMON_PERMISSIONS.CFEI_FINALIZE, state)),
    isPublishPermissionAllowed: isPublishPermissionAllowed,
    isPartnerVerified: state?.projectDetails?.data?.data?.direct_selected_partners?.at(0)?.partner_is_verified ?? false,
    isPartnerProfileComplete: state?.projectDetails?.data?.data?.direct_selected_partners?.at(0)?.partner_profile_is_complete ?? false,
    isHq: state?.session?.isHq,
    isProfileComplete: state?.session?.isProfileComplete,
    updatedDate: state?.session?.lastUpdate,
    projectApplicationId: state?.unProjectApplicationDetails?.data?.data?.id,
    isConceptNoteUploaded: state?.partnerProjectApplication?.data?.data?.cn ? true : false,
    submitDate: state?.partnerProjectApplication?.data?.data?.created,
    applicationId: state?.partnerProjectApplication?.data?.data?.id,
    conceptNoteSubmissionFormValues: state?.form?.conceptNoteSubmissionForm?.values,
    partnerProfile: state?.partnerProfileDataDetails?.data?.data ?? {},
    feedback: state?.applicationFeedbackList?.data?.data ?? [],
    allowedToAddDirectFeedback: isFocalPoint || isCreator,
    officeId: state.session.officeId,
    invitePartnersFormValues: state.form?.invitePartnersModalForm?.values ?? {},
    manageReviewersFormValues: state.form?.manageReviewersModalForm?.values ?? {},
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  uploadCnClearState: () => dispatch(clearLocalState()),
  getProjectDetails: () => dispatch(getProjectDetails(undefined, { id: ownProps?.match?.params?.id })),
  getProjectApplicationDetails: () =>
    dispatch(
      getProjectApplicationDetails(undefined, {
        id: ownProps?.match?.params?.id,
      }),
    ),
  loadProjectApplication: () =>
    dispatch(
      getPartnerProjectApplication(undefined, {
        projectId: ownProps?.match?.params?.id,
      }),
    ),
  getOpenProjectApplications: (params) =>
    dispatch(
      getOpenProjectApplicationsAllList(params, {
        projectId: ownProps?.match?.params?.id,
      }),
    ),
  invitePartners: (params) => dispatch(invitePartners(params, { projectId: ownProps?.match?.params?.id })),
  updateReviewers: (params) => dispatch(updateReviewers(params, { projectId: ownProps?.match?.params?.id })),
  getPreselectedApplications: (params) =>
    dispatch(
      getOpenProjectApplicationsPreselectedRecommendedList(
        {
          ...params,
          status: [APPLICATION_STATUSES.PRE, APPLICATION_STATUSES.REC].join(","),
        },
        { projectId: ownProps?.match?.params?.id },
      ),
    ),
  updateProjectApplication: (id, status) => dispatch(updateProjectApplication({ status }, { id })),
  getOpenProjectRequests: (params) => dispatch(getOpenProjectRequestsList(params, { id: ownProps?.match?.params?.id })),
  getSectors: (params) => dispatch(getSectorsList(params)),
  togglePin: (pin) => dispatch(togglePin({ pin, eoi_ids: [ownProps?.match?.params?.id] })),
  getCountries: () => dispatch(getCountriesList()),
  getLocations: (params) => dispatch(getLocationsList(params)),
  getClarificationRequests: () =>
    dispatch(
      getClarificationRequestsList(undefined, {
        projectId: ownProps?.match?.params?.id,
      }),
    ),
  getClarificationAnswers: () =>
    dispatch(
      getClarificationAnswersList(undefined, {
        projectId: ownProps?.match?.params?.id,
      }),
    ),
  addRequest: (projectId, body) => dispatch(addClarificationRequest(body, { projectId })),
  addAnswer: (projectId, body) => dispatch(addClarificationAnswer(body, { projectId })),
  deleteAnswer: (id) => dispatch(removeClarificationAnswer({ id })),
  completeAssessment: () => dispatch(completeAssessment(undefined, { id: ownProps?.match?.params?.id })),
  uploadConceptNote: (body) => dispatch(uploadConceptNote(body, { id: ownProps?.match?.params?.id })),
  deleteConceptNote: (id) => dispatch(deleteUploadedConceptNote({ id })),
  deleteProject: () => dispatch(deleteProject({ id: ownProps?.match?.params?.id })),
  cancelProject: () => dispatch(cancelProject({ id: ownProps?.match?.params?.id })),
  resetConceptNoteSubmissionForm: () => dispatch(reset("conceptNoteSubmissionForm")),
  resetAddClarificationForm: () => dispatch(reset("addClarificationForm")),
  getPartnerProfileData: (id) => dispatch(getPartnerProfileDataDetails(undefined, { id })),
  getPartnersToInvite: (legal_name) =>
    dispatch(
      getPartnersToInviteList({
        is_hq: "False",
        legal_name,
        exclude_special_partners: "True",
      }),
    ),
  getReviewers: (officeId, name) => dispatch(getAgencyReviewersList({ reviewer: "true", name }, { officeId })),
  deleteUCN: () => dispatch(deleteUCN({ id: ownProps?.match?.params?.id })),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectDetails));
