/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router";
import { reset, isValid } from "redux-form";
import {
  getProjectApplicationDetails,
  getApplicationReviews,
  getProjectDetails,
  getPartnerSummaryDetails,
  getReviewersList,
  createApplicationReview,
  updateApplicationReview,
  updateProjectApplication,
  getApplicationFeedbackList,
  postApplicationFeedback,
} from "@local/actions";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Grid from "@mui/material/Grid";
import Button from "@local/components/button";
import ConceptNote from "@local/pages/project-details/components/overview/components/concept-note";
import FeedbackItem from "@local/components/feedback-item";
import { isRoleOffice, AGENCY_ROLES, checkPermission, AGENCY_PERMISSIONS } from "@local/legacy-utils/permissions";
import Container from "./components/container";
import Reviews from "./components/reviews";
import ManageReviewModal from "./components/manage-review-modal";
import WithdrawApplicationModal from "./components/withdraw-application-modal";
import ProfileSummary from "@local/pages/partner-details/components/overview/components/profile-summary";
import { PROJECT_STATUSES, APPLICATION_STATUSES, getSelectionCriteria } from "@local/utils/constants";
import { toast } from "react-toastify";
import { getReviewModalInitialValues } from "./utils/adapters";

const ApplicationDetails = ({
  getProjectApplicationDetails,
  application,
  isCreator,
  isFocalPoint,
  projectStatus,
  isAdvEd,
  isMFT,
  isPAM,
  isBasEd,
  isReviewer,
  hasViewAssessmentsPermission,
  loading,
  isCompleted,
  isProjectDetailsExist,
  getProjectDetails,
  user,
  shouldGetReviews,
  getPartnerSummary,
  getApplicationReviews,
  reviews,
  getReviewers,
  getApplicationFeedbacks,
  hasRetractPermission,
  hasAssessPermission,
  isDeadlinePassed,
  isSingleReviewer,
  partnerName,
  criteria,
  createApplicationReview,
  updateApplicationReview,
  updateProjectApplication,
  manageReviewFormValues,
  withdrawApplicationFormValues,
  partner,
  isAdvEdPlus,
  isAdvEdSecretariat,
  selectionCriteria,
  isManageReviewFormValid,
  feedbackFormValues,
  createApplicationFeedback,
  resetFeedbackForm,
}) => {
  const history = useHistory();

  const [isManageReviewModalVisible, setIsManageReviewModalVisible] = useState(false);
  const [isWithdrawApplicationModalVisible, setIsWithdrawApplicationModalVisible] = useState(false);
  const [assessment, setAssessment] = useState(undefined);
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (reviews) {
      setAssessment(reviews?.find((review) => review?.id === user)?.assessment?.at(0) ?? null);
      setIsEditable(reviews?.find((review) => review?.id === user)?.assessment?.at(0) ? true : false);
    }
  }, [reviews, user]);

  const onClickManageReview = () => {
    setAssessment(reviews?.find((review) => review?.id === user)?.assessment?.at(0) ?? null);
    setIsManageReviewModalVisible(true);
    setIsEditable(true);
  };

  const onClickCloseManageReviewModal = () => {
    setIsManageReviewModalVisible(false);
    setIsEditable(false);
  };

  const onClickWithdrawApplication = () => {
    setIsWithdrawApplicationModalVisible(true);
  };

  const onClickCloseWithdrawApplicationModal = () => {
    setIsWithdrawApplicationModalVisible(false);
  };

  const onClickSubmitManageReview = async () => {
    try {
      if (criteria?.filter((item) => parseFloat(item.weight) < parseFloat(manageReviewFormValues[`score_${item.selection_criteria}`]))?.length > 0) {
        toast.error(
          formatMessage({
            id: "pages.application.details.idx.scoreTooHightError",
          }),
        );
      } else {
        const values = {
          is_a_committee_score: manageReviewFormValues?.is_a_committee_score ?? false,
          note: manageReviewFormValues?.note ?? "",
          scores:
            criteria?.map((item) => ({
              description: item.description,
              score: parseFloat(manageReviewFormValues[`score_${item.selection_criteria}`]),
              selection_criteria: item.selection_criteria,
              weight: item?.weight ? parseFloat(item.weight) : undefined,
            })) ?? undefined,
        };
        if (assessment) await updateApplicationReview(values, user);
        else await createApplicationReview(values, user);
        setIsManageReviewModalVisible(false);
        loadData();
      }
    } catch (error) {
      toast.error(formatMessage({ id: "pages.application.details.idx.unableToSaveData" }));
    }
  };

  const onClickSubmitWithdrawApplication = async () => {
    await updateProjectApplication({
      ...withdrawApplicationFormValues,
      did_withdraw: true,
    });
    setIsWithdrawApplicationModalVisible(false);
  };

  const isViewAssessmentsAllowed =
    (hasViewAssessmentsPermission && (isAdvEd || isAdvEdPlus || isAdvEdSecretariat) && (isCreator || isFocalPoint || isReviewer)) ||
    (hasViewAssessmentsPermission && isBasEd && (isCreator || isReviewer)) ||
    (hasViewAssessmentsPermission && isMFT && isFocalPoint) ||
    (hasViewAssessmentsPermission && isMFT && isReviewer) ||
    (hasViewAssessmentsPermission && isPAM && isReviewer) ||
    (hasViewAssessmentsPermission && isPAM && isCreator);

  const isRetractAllowed = (hasRetractPermission && (isAdvEd || isAdvEdPlus || isAdvEdSecretariat) && (isCreator || isFocalPoint)) || (hasRetractPermission && isMFT && isFocalPoint);

  const isAssessActionAllowed =
    (hasAssessPermission && (isAdvEd || isAdvEdPlus || isAdvEdSecretariat) && isReviewer) ||
    (hasAssessPermission && isBasEd && isReviewer) ||
    (hasAssessPermission && isMFT && isReviewer) ||
    (hasAssessPermission && isPAM && isReviewer) ||
    (hasAssessPermission && isPAM && isCreator);

  const loadData = async () => {
    const applicationData = await getProjectApplicationDetails();
    const application = applicationData?.data;
    if (application) {
      if (!isProjectDetailsExist(application?.eoi)) {
        const projectData = await getProjectDetails(application?.eoi);
        const { focal_points, reviewers } = projectData?.data ?? {
          focal_points: [],
          reviewers: [],
        };
        if (focal_points.includes(user) || reviewers.includes(user)) getApplicationReviews();
      } else if (shouldGetReviews) getApplicationReviews();
      getPartnerSummary(application?.partner?.id);
    }
    getReviewers();
    getApplicationFeedbacks();
  };

  useEffect(() => {
    loadData();
  }, []);

  const onClickSendFeedback = async () => {
    try {
      await createApplicationFeedback(feedbackFormValues);
      await getApplicationFeedbacks();
      resetFeedbackForm();
    } catch (error) {
      toast.error(formatMessage({ id: "pages.application.details.idx.error" }));
    }
  };

  return (
    <>
      <Container
        application={application}
        partner={application?.partner?.legal_name}
        projectStatus={projectStatus}
        loading={loading}
        isCompleted={isCompleted}
        isRetractAllowed={isRetractAllowed}
        hasAssessPermission={hasAssessPermission}
        isAssessActionAllowed={isAssessActionAllowed}
        isDeadlinePassed={isDeadlinePassed}
        onClickManageReview={onClickManageReview}
        onClickWithdrawApplication={onClickWithdrawApplication}
        assessment={assessment}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <ProfileSummary
              partner={partner}
              bottomButton={
                <Button
                  text={formatMessage({
                    id: "pages.application.details.idx.viewProfile",
                  })}
                  variant="text"
                  onClick={() => history.push(`/partner/${partner?.id}/details`)}
                />
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ConceptNote
                  title={formatMessage({
                    id: "pages.application.details.idx.cn",
                  })}
                  conceptNote={application?.cn?.file_field}
                  date={application?.created}
                />
              </Grid>
            </Grid>
          </Grid>
          {isViewAssessmentsAllowed && application?.status === APPLICATION_STATUSES.PRE && projectStatus === PROJECT_STATUSES.CLO ? (
            <Grid item xs={12}>
              <Reviews application={application} isCreator={isCreator} isFocalPoint={isFocalPoint} isReviewer={isReviewer} reviews={reviews} user={user} criteria={criteria} selectionCriteria={selectionCriteria} />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={8}>
            <FeedbackItem allowedToAddFeedback={isCreator || isFocalPoint} isCompleted={isCompleted} isCreator={isCreator} isFocalPoint={isFocalPoint} applicationId={application?.id} onClickSendFeedback={onClickSendFeedback} />
          </Grid>
        </Grid>
      </Container>
      {assessment !== undefined ? (
        <ManageReviewModal
          title={isEditable ? formatMessage({ id: "pages.application.details.idx.title" }) : formatMessage({ id: "pages.application.details.idx.titleOne" })}
          initialValues={isEditable ? getReviewModalInitialValues(assessment, isSingleReviewer) : undefined}
          isOpen={isManageReviewModalVisible}
          onClose={onClickCloseManageReviewModal}
          onSubmit={onClickSubmitManageReview}
          isSingleReviewer={isSingleReviewer}
          partnerName={partnerName}
          reviewer={user}
          criteria={criteria}
          selectionCriteria={selectionCriteria}
          isFormValid={isManageReviewFormValid}
        />
      ) : undefined}
      <WithdrawApplicationModal isOpen={isWithdrawApplicationModalVisible} onClose={onClickCloseWithdrawApplicationModal} onSubmit={onClickSubmitWithdrawApplication} />
    </>
  );
};

ApplicationDetails.propTypes = {};

const mapStateToProps = (state) => {
  const isFocalPoint = state?.projectDetails?.data?.data?.focal_points?.includes(state?.session?.userId);
  const isReviewer = state?.projectDetails?.data?.data?.reviewers?.includes(state?.session?.userId);
  const isCreator = state?.projectDetails?.data?.data?.created_by === state?.session?.userId;

  return {
    application: state?.unProjectApplicationDetails?.data?.data,
    isCreator: isCreator,
    isFocalPoint: isFocalPoint,
    projectStatus: state?.projectDetails?.data?.data?.status,
    isAdvEd: isRoleOffice(AGENCY_ROLES.EDITOR_ADVANCED, state),
    isMFT: isRoleOffice(AGENCY_ROLES.MFT_USER, state),
    isPAM: isRoleOffice(AGENCY_ROLES.PAM_USER, state),
    isBasEd: isRoleOffice(AGENCY_ROLES.EDITOR_BASIC, state),
    isAdvEdPlus: isRoleOffice(AGENCY_ROLES.EDITOR_ADVANCED_PLUS, state),
    isAdvEdSecretariat: isRoleOffice(AGENCY_ROLES.EDITOR_ADVANCED_SECRETARIAT, state),
    isReviewer: isReviewer,
    isDeadlinePassed: state?.projectDetails?.data?.data?.deadline_passed,
    hasViewAssessmentsPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_VIEW_ALL_REVIEWS, state),
    hasRetractPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_DESELECT_PARTNER, state),
    hasAssessPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_REVIEW_APPLICATIONS, state),
    loading: state?.unProjectApplicationDetails?.status?.loading,
    reviews: state?.applicationReviewsList?.data?.data ?? undefined,
    isCompleted: state?.projectDetails?.data?.data?.is_completed,
    isProjectDetailsExist: (id) => state?.projectDetails?.data?.data?.id === id,
    user: state?.session?.userId,
    shouldGetReviews: isFocalPoint || isReviewer || isCreator,
    reviewers: state?.reviewersList?.data?.data,
    isSingleReviewer: state?.projectDetails?.data?.data?.reviewers.length === 1,
    criteria: state?.projectDetails?.data?.data?.assessments_criteria,
    partnerName: state?.unProjectApplicationDetails?.data?.data?.partner.legal_name,
    manageReviewFormValues: state?.form?.manageReviewForm?.values,
    withdrawApplicationFormValues: state?.form?.withdrawApplicationForm?.values,
    partner: state.partnerSummaryDetails.data?.data ?? {},
    selectionCriteria: getSelectionCriteria(),
    isManageReviewFormValid: isValid("manageReviewForm")(state),
    feedbackFormValues: state?.form?.feedbackForm?.values,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getProjectApplicationDetails: () =>
    dispatch(
      getProjectApplicationDetails(undefined, {
        id: ownProps?.match?.params?.applicationId,
      }),
    ),
  getApplicationReviews: () =>
    dispatch(
      getApplicationReviews(undefined, {
        applicationId: ownProps?.match?.params?.applicationId,
      }),
    ),
  getProjectDetails: (id) => dispatch(getProjectDetails(undefined, { id })),
  getPartnerSummary: (id) => dispatch(getPartnerSummaryDetails(undefined, { id })),
  getReviewers: () => dispatch(getReviewersList(undefined, { id: ownProps?.match?.params?.id })),
  getApplicationFeedbacks: () =>
    dispatch(
      getApplicationFeedbackList(undefined, {
        applicationId: ownProps?.match?.params?.applicationId,
      }),
    ),
  updateProjectApplication: (body) =>
    dispatch(
      updateProjectApplication(body, {
        id: ownProps?.match?.params?.applicationId,
      }),
    ),
  createApplicationReview: (body, reviewerId) =>
    dispatch(
      createApplicationReview(body, {
        applicationId: ownProps?.match?.params?.applicationId,
        reviewerId,
      }),
    ),
  updateApplicationReview: (body, reviewerId) =>
    dispatch(
      updateApplicationReview(body, {
        applicationId: ownProps?.match?.params?.applicationId,
        reviewerId,
      }),
    ),
  createApplicationFeedback: (body) =>
    dispatch(
      postApplicationFeedback(body, {
        applicationId: ownProps?.match?.params?.applicationId,
      }),
    ),
  resetFeedbackForm: () => dispatch(reset("feedbackForm")),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationDetails));
