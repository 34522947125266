import React, { useMemo } from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import style from "./style.module.scss";
import imageLogo from "@local/assets/images/login-page-logo.png";
import imageBackground from "@local/assets/images/login-page-background.jpg";
import imageUn from "@local/assets/images/login-page-un.png";
import imageFao from "@local/assets/images/login-page-fao.png";
import imageWho from "@local/assets/images/login-page-who.png";
import imageUnfpa from "@local/assets/images/login-page-unfpa.png";
import imageUnhcr from "@local/assets/images/login-page-unhcr.png";
import imageUnicef from "@local/assets/images/login-page-unicef.png";
import imageUnwomen from "@local/assets/images/login-page-unwomen.png";
import imageIom from "@local/assets/images/login-page-iom.png";
import imageUnops from "@local/assets/images/login-page-unops.png";
import imageUndp from "@local/assets/images/login-page-undp.png";
import imageWfp from "@local/assets/images/login-page-wfp.png";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import Button from "@local/components/button";
import { reduxForm, Field } from "redux-form";
import FieldGroup from "@local/components/field-group";
import FieldAutosuggest from "@local/components/field-autosuggest/";
import FieldText from "@local/components/field-text";
import { isBetween, isRequired, isValidDate, isValidEmail } from "@local/utils/validators";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Divider from "@mui/material/Divider";
import Alert from "@local/components/alert";
import FieldSelect from "@local/components/field-select";
import FieldRadioGroup from "@local/components/field-radio-group/index";
import FieldDatePicker from "@local/components/field-date-picker";
import FieldFile from "@local/components/field-file";
import FieldCheckbox from "@local/components/field-checkbox";
import Logout from "@mui/icons-material/Logout";
import LanguageTrigger from "@local/components/language-trigger";
import FieldListInput from "@local/components/field-list-input";
import { adaptPartnersOptions } from "../../utils/adapters";
import PartnerDeclarationQuestion from "@local/components/partner-declaration-question";
import { getPartnerDeclarationQuestions } from "@local/utils/adapters";

const getContentByCurrentStep = (
  currentStep,
  onClickContinueAsPartner,
  getPartnersBasicInformation,
  onClickRequestPartnerAccess,
  onClickCreateNewPartnerProfile,
  onClickCreateNewAgencyMember,
  fullName,
  onClickSendNewRequest,
  newPartnerCurrentStep,
  onClickNextStep,
  onClickPreviousStep,
  partnerTypes,
  countries,
  formValues,
  onChangeIsHq,
  isYearBetween,
  agencyCountryOffices,
) => {
  const isPartnerDeclarationDisabled =
    !formValues?.ad_1 ||
    formValues?.ad_1 === "false" ||
    !formValues?.ad_2 ||
    formValues?.ad_2 === "false" ||
    !formValues?.ad_3 ||
    formValues?.ad_3 === "false" ||
    !formValues?.ad_4 ||
    formValues?.ad_4 === "false" ||
    !formValues?.ad_5 ||
    formValues?.ad_5 === "false" ||
    !formValues?.ad_6 ||
    formValues?.ad_6 === "false" ||
    !formValues?.ad_7 ||
    formValues?.ad_7 === "false" ||
    !formValues?.ad_8 ||
    formValues?.ad_8 === "false" ||
    !formValues?.ad_9 ||
    formValues?.ad_9 === "false" ||
    !formValues?.ad_10 ||
    formValues?.ad_10 === "false" ||
    !formValues?.ad_11 ||
    formValues?.ad_11 === "false" ||
    !formValues?.ad_12 ||
    formValues?.ad_12 === "false";

  switch (currentStep) {
    case "welcome":
      return (
        <>
          <div className={style.sectionWrapper}>
            <span className={style.firstDescription}>
              {formatMessage({
                id: "pages.first.access.cmp.mn.idx.firstStepLabel",
              })}
            </span>
            <Button
              text={formatMessage({
                id: "pages.first.access.cmp.mn.idx.firstStepButton",
              })}
              onClick={onClickContinueAsPartner}
            />
          </div>
        </>
      );
    case "shell-profile":
      return (
        <>
          <div className={style.sectionWrapper}>
            <span className={style.firstDescription}>
              {formatMessage({
                id: "pages.first.access.cmp.mn.idx.description",
              })}
            </span>
            <Button
              text={formatMessage({
                id: "pages.first.access.cmp.mn.idx.create",
              })}
              onClick={onClickCreateNewPartnerProfile}
            />
          </div>
        </>
      );
    case "agency-access":
      return (
        <>
          <div className={style.sectionWrapper}>
            <span className={style.agencyFirstDescription}>
              {formatMessage({
                id: "pages.first.access.cmp.mn.idx.countryDialogInfo",
              })}
            </span>
          </div>
          <FieldGroup>
            <Field
              component={FieldSelect}
              validate={[isRequired]}
              id="agency_country_offices"
              name="agency_country_offices"
              label={formatMessage({
                id: "pages.first.access.cmp.mn.idx.countryOffice",
              })}
              placeholder={formatMessage({
                id: "pages.first.access.cmp.mn.idx.selectCountryOffice",
              })}
              options={agencyCountryOffices}
              valueField="value"
              labelField="label"
            />
            <div className={style.sendActionsWrapper}>
              <Button
                isDisabled={isRequired(formValues?.agency_country_offices) !== ""}
                text={formatMessage({
                  id: "pages.first.access.cmp.mn.idx.submit",
                })}
                onClick={onClickCreateNewAgencyMember}
              />
            </div>
          </FieldGroup>
        </>
      );
    case "partner-welcome":
      return (
        <>
          <div className={style.sectionWrapper}>
            <div className={style.sectionContent}>
              <span className={style.description}>
                {formatMessage({
                  id: "pages.first.access.cmp.mn.idx.newPartnerProfileLabel",
                })}
              </span>
              <Button
                text={formatMessage({
                  id: "pages.first.access.cmp.mn.idx.newPartnerProfileButton",
                })}
                onClick={onClickCreateNewPartnerProfile}
              />
            </div>
          </div>
          <Divider className={style.sectionWrapper}>OR</Divider>
          <div className={style.sectionWrapper}>
            <span className={style.description}>
              {formatMessage({
                id: "pages.first.access.cmp.mn.idx.sendRequestAdministrator",
              })}
            </span>
            <FieldGroup>
              <Field
                component={FieldAutosuggest}
                validate={[isRequired]}
                id="partner"
                name="partner"
                label={formatMessage({
                  id: "pages.first.access.cmp.mn.idx.legalName",
                })}
                placeholder={formatMessage({
                  id: "pages.first.access.cmp.mn.idx.legalName",
                })}
                fetchFunction={getPartnersBasicInformation}
                valueField="id"
                labelField="label"
                adapterFunction={adaptPartnersOptions}
              />
              <Field
                component={FieldText}
                validate={[isRequired, isValidEmail]}
                id="admin"
                name="admin"
                label={formatMessage({
                  id: "pages.first.access.cmp.mn.idx.administratorEmail",
                })}
                placeholder={formatMessage({
                  id: "pages.first.access.cmp.mn.idx.administratorEmail",
                })}
                type="email"
              />
            </FieldGroup>
            <div className={style.sendActionsWrapper}>
              <Button
                isDisabled={isRequired(formValues?.partner) !== "" || isValidEmail(formValues?.admin) !== ""}
                text={formatMessage({
                  id: "pages.first.access.cmp.mn.idx.sendRequest",
                })}
                onClick={onClickRequestPartnerAccess}
              />
            </div>
          </div>
        </>
      );
    case "partner-steps":
      return (
        <>
          <div className={style.sectionWrapper}>
            <Stepper activeStep={newPartnerCurrentStep} orientation="vertical">
              <Step>
                <StepLabel>
                  {formatMessage({
                    id: "pages.first.access.cmp.mn.idx.selectType",
                  })}
                </StepLabel>
                <StepContent>
                  <div className={style.stepWrapper}>
                    <div className={style.alertWrapper}>
                      <Alert
                        content={
                          <span>
                            {formatMessage({
                              id: "pages.first.access.cmp.mn.idx.informationText",
                            })}{" "}
                            <a
                              target="_blank"
                              href="https://unicef.org/"
                              alt={formatMessage({
                                id: "pages.first.access.cmp.mn.idx.learnMore",
                              })}
                              rel="noreferrer"
                            >
                              {formatMessage({
                                id: "pages.first.access.cmp.mn.idx.learnMore",
                              })}
                            </a>
                          </span>
                        }
                      />
                    </div>
                    <FieldGroup>
                      <Field
                        component={FieldSelect}
                        validate={[isRequired]}
                        id="display_type"
                        name="display_type"
                        label={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.projectDetailsType",
                        })}
                        placeholder={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.projectDetailsType",
                        })}
                        valueField="value"
                        labelField="label"
                        options={partnerTypes}
                        informationText={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.tooltip",
                        })}
                      />
                      {formValues?.display_type === "Int" ? (
                        <Field
                          component={FieldRadioGroup}
                          validate={[isRequired]}
                          small
                          id="is_hq"
                          name="is_hq"
                          label={formatMessage({
                            id: "pages.first.access.cmp.mn.idx.office",
                          })}
                          onChangeExtra={onChangeIsHq}
                          options={[
                            {
                              value: true,
                              label: formatMessage({
                                id: "pages.first.access.cmp.mn.idx.headquarters",
                              }),
                            },
                            {
                              value: false,
                              label: formatMessage({
                                id: "pages.first.access.cmp.mn.idx.country",
                              }),
                            },
                          ]}
                        />
                      ) : undefined}
                    </FieldGroup>
                  </div>
                  <div className={style.actionsWrapper}>
                    <Button
                      isDisabled={isRequired(formValues?.display_type) !== "" || (formValues?.display_type === "Int" && isRequired(formValues?.is_hq) !== "")}
                      text={formatMessage({
                        id: "pages.first.access.cmp.mn.idx.continue",
                      })}
                      onClick={onClickNextStep}
                    />
                  </div>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>
                  {formatMessage({
                    id: "pages.first.access.cmp.mn.idx.basicInformation",
                  })}
                </StepLabel>
                <StepContent>
                  <div className={style.stepWrapper}>
                    <FieldGroup>
                      <Field
                        component={FieldText}
                        validate={[isRequired]}
                        id="legal_name"
                        name="legal_name"
                        label={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.legalNameOne",
                        })}
                        placeholder={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.legalNameOne",
                        })}
                        type="text"
                      />
                      <Field
                        component={FieldListInput}
                        id="other_names"
                        name="other_names"
                        label={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.otherNames",
                        })}
                        placeholder={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.otherNames",
                        })}
                        emptyPlaceholder={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.otherNamesEmpty",
                        })}
                      />
                      <Field
                        component={FieldSelect}
                        validate={[isRequired]}
                        id="country_code"
                        name="country_code"
                        label={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.countryOne",
                        })}
                        placeholder={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.countryOne",
                        })}
                        valueField="value"
                        labelField="label"
                        options={countries}
                        informationText={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.tooltipOne",
                        })}
                      />
                      <Field
                        component={FieldText}
                        validate={[isRequired]}
                        id="head_organization_fullname"
                        name="head_organization_fullname"
                        label={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.fullName",
                        })}
                        placeholder={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.fullName",
                        })}
                      />
                      <Field
                        component={FieldText}
                        validate={[isRequired, isValidEmail]}
                        id="head_organization_email"
                        name="head_organization_email"
                        label={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.email",
                        })}
                        placeholder={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.email",
                        })}
                        type="email"
                      />
                    </FieldGroup>
                  </div>
                  <div className={style.actionsWrapper}>
                    <Button
                      variant="text"
                      text={formatMessage({
                        id: "pages.first.access.cmp.mn.idx.back",
                      })}
                      onClick={onClickPreviousStep}
                    />
                    <Button
                      isDisabled={
                        isRequired(formValues?.legal_name) !== "" ||
                        isRequired(formValues?.country_code) !== "" ||
                        isRequired(formValues?.head_organization_fullname) !== "" ||
                        isRequired(formValues?.head_organization_email) !== "" ||
                        isValidEmail(formValues?.head_organization_email) !== ""
                      }
                      text={formatMessage({
                        id: "pages.first.access.cmp.mn.idx.continue",
                      })}
                      onClick={onClickNextStep}
                    />
                  </div>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>
                  {formatMessage({
                    id: "pages.first.access.cmp.mn.idx.legalStatus",
                  })}
                </StepLabel>
                <StepContent>
                  <div className={style.stepWrapper}>
                    <FieldGroup>
                      <Field
                        component={FieldText}
                        validate={[isRequired, isYearBetween]}
                        id="year_establishment"
                        name="year_establishment"
                        label={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.yearEstablishment",
                        })}
                        type="number"
                      />
                      <Field
                        component={FieldRadioGroup}
                        validate={[isRequired]}
                        small
                        fullWidthLabel
                        id="registered_to_operate_in_country"
                        name="registered_to_operate_in_country"
                        label={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.registrationCountry",
                        })}
                        options={[
                          {
                            value: true,
                            label: formatMessage({
                              id: "pages.first.access.cmp.mn.idx.yes",
                            }),
                          },
                          {
                            value: false,
                            label: formatMessage({
                              id: "pages.first.access.cmp.mn.idx.no",
                            }),
                          },
                        ]}
                      />
                      {formValues?.registered_to_operate_in_country?.toString() === "true" ? (
                        <>
                          <Field
                            component={FieldFile}
                            validate={[isRequired]}
                            isLocalFile
                            id="registration_document"
                            name="registration_document"
                            label={formatMessage({
                              id: "pages.first.access.cmp.mn.idx.document",
                            })}
                            placeholder={formatMessage({
                              id: "pages.first.access.cmp.mn.idx.document",
                            })}
                          />
                          <Field
                            component={FieldText}
                            validate={[isRequired]}
                            id="issuing_authority"
                            name="issuing_authority"
                            label={formatMessage({
                              id: "pages.first.access.cmp.mn.idx.name",
                            })}
                            placeholder={formatMessage({
                              id: "pages.first.access.cmp.mn.idx.name",
                            })}
                            type="text"
                          />
                          <Field
                            component={FieldDatePicker}
                            validate={[isRequired, isValidDate]}
                            id="issue_date"
                            name="issue_date"
                            maximumDate={new Date()}
                            label={formatMessage({
                              id: "pages.first.access.cmp.mn.idx.date",
                            })}
                            placeholder={formatMessage({
                              id: "pages.first.access.cmp.mn.idx.date",
                            })}
                          />
                          <Field
                            component={FieldText}
                            validate={[isRequired]}
                            id="registration_number"
                            name="registration_number"
                            label={formatMessage({
                              id: "pages.first.access.cmp.mn.idx.number",
                            })}
                            placeholder={formatMessage({
                              id: "pages.first.access.cmp.mn.idx.number",
                            })}
                            type="text"
                            maxLength={50}
                          />
                        </>
                      ) : undefined}
                      {formValues?.registered_to_operate_in_country === "false" ? (
                        <Field
                          component={FieldText}
                          validate={[isRequired]}
                          id="missing_registration_document_comment"
                          name="missing_registration_document_comment"
                          label={formatMessage({
                            id: "pages.first.access.cmp.mn.idx.comment",
                          })}
                          placeholder={formatMessage({
                            id: "pages.first.access.cmp.mn.idx.comment",
                          })}
                          type="text"
                          maxLength={50}
                        />
                      ) : undefined}
                      <Field
                        component={FieldRadioGroup}
                        validate={[isRequired]}
                        small
                        fullWidthLabel
                        id="have_governing_document"
                        name="have_governing_document"
                        label={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.haveGovDoc",
                        })}
                        options={[
                          {
                            value: true,
                            label: formatMessage({
                              id: "pages.first.access.cmp.mn.idx.yes",
                            }),
                          },
                          {
                            value: false,
                            label: formatMessage({
                              id: "pages.first.access.cmp.mn.idx.no",
                            }),
                          },
                        ]}
                      />
                      {formValues?.have_governing_document?.toString() === "true" ? (
                        <Field
                          component={FieldFile}
                          validate={[isRequired]}
                          isLocalFile
                          id="governing_document"
                          name="governing_document"
                          label={formatMessage({
                            id: "pages.first.access.cmp.mn.idx.button",
                          })}
                          placeholder={formatMessage({
                            id: "pages.first.access.cmp.mn.idx.button",
                          })}
                          informationText={formatMessage({
                            id: "pages.first.access.cmp.mn.idx.governingDocument",
                          })}
                        />
                      ) : undefined}
                      {formValues?.have_governing_document === "false" ? (
                        <Field
                          component={FieldText}
                          validate={[isRequired]}
                          id="missing_governing_document_comment"
                          name="missing_governing_document_comment"
                          label={formatMessage({
                            id: "pages.first.access.cmp.mn.idx.comment",
                          })}
                          placeholder={formatMessage({
                            id: "pages.first.access.cmp.mn.idx.comment",
                          })}
                          type="text"
                          maxLength={50}
                        />
                      ) : undefined}
                    </FieldGroup>
                  </div>
                  <div className={style.actionsWrapper}>
                    <Button
                      variant="text"
                      text={formatMessage({
                        id: "pages.first.access.cmp.mn.idx.back",
                      })}
                      onClick={onClickPreviousStep}
                    />
                    <Button
                      isDisabled={
                        isRequired(formValues?.year_establishment) !== "" ||
                        isBetween(1000, new Date().getFullYear())(formValues?.year_establishment) !== "" ||
                        isRequired(formValues?.have_governing_document) !== "" ||
                        isRequired(formValues?.registered_to_operate_in_country) !== "" ||
                        (formValues?.registered_to_operate_in_country?.toString() === "true" &&
                          (isRequired(formValues?.registration_document) !== "" ||
                            isRequired(formValues?.issuing_authority) !== "" ||
                            isRequired(formValues?.issue_date) !== "" ||
                            isRequired(formValues?.registration_number) !== "" ||
                            isValidDate(formValues?.issue_date) !== "")) ||
                        (formValues?.registered_to_operate_in_country === "false" && isRequired(formValues?.missing_registration_document_comment) !== "") ||
                        (formValues?.have_governing_document?.toString() === "true" && isRequired(formValues?.governing_document) !== "") ||
                        (formValues?.have_governing_document === "false" && isRequired(formValues?.missing_governing_document_comment) !== "")
                      }
                      text={formatMessage({
                        id: "pages.first.access.cmp.mn.idx.continue",
                      })}
                      onClick={onClickNextStep}
                    />
                  </div>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>
                  {formatMessage({
                    id: "pages.first.access.cmp.mn.idx.partnerDeclaration",
                  })}
                </StepLabel>
                <StepContent>
                  <div className={style.stepWrapper}>
                    <span className={style.stepDescription}>
                      {formatMessage({
                        id: "pages.first.access.cmp.mn.idx.partnerDeclarationLabel",
                      })}
                    </span>
                    <>
                      {getPartnerDeclarationQuestions(formValues)?.map((item) =>
                        item?.isVisible ? <PartnerDeclarationQuestion id={item?.id} text={item?.content} nameField={item?.nameField} isPartnerDeclarationRequired={item?.errorMessage} formValues={formValues} /> : undefined,
                      )}
                      {!isPartnerDeclarationDisabled && formValues.ad_12 === "true" ? (
                        <div className={style.extraInfoWrapper}>
                          <span className={style.stepDescription}>
                            {formatMessage({
                              id: "pages.first.access.cmp.mn.idx.part1",
                            })}
                          </span>
                          <span className={style.stepDescription}>
                            {formatMessage({
                              id: "pages.first.access.cmp.mn.idx.part2",
                            })}
                          </span>
                          <span className={style.stepDescription}>
                            {formatMessage({
                              id: "pages.first.access.cmp.mn.idx.part3",
                            })}
                          </span>
                        </div>
                      ) : undefined}
                    </>
                  </div>
                  <div className={style.actionsWrapper}>
                    <Button
                      variant="text"
                      text={formatMessage({
                        id: "pages.first.access.cmp.mn.idx.back",
                      })}
                      onClick={onClickPreviousStep}
                    />
                    <Button
                      isDisabled={isPartnerDeclarationDisabled}
                      text={formatMessage({
                        id: "pages.first.access.cmp.mn.idx.continue",
                      })}
                      onClick={onClickNextStep}
                    />
                  </div>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>
                  {formatMessage({
                    id: "pages.first.access.cmp.mn.idx.termsTitle",
                  })}
                </StepLabel>
                <StepContent>
                  <div className={style.stepWrapper}>
                    <FieldGroup>
                      <Field
                        component={FieldCheckbox}
                        small
                        fullWidthLabel
                        validate={[isRequired]}
                        id="term_of_use"
                        name="term_of_use"
                        label={
                          <>
                            {formatMessage({
                              id: "pages.first.access.cmp.mn.idx.terms1",
                            })}{" "}
                            <a
                              target="_blank"
                              href="https://unpartnerportalcso.zendesk.com/hc/en-us/article_attachments/360018648633/UN_Partner_Portal_Terms_of_Use.pdf"
                              alt={formatMessage({
                                id: "pages.first.access.cmp.mn.idx.termsLink1",
                              })}
                              rel="noreferrer"
                            >
                              {formatMessage({
                                id: "pages.first.access.cmp.mn.idx.termsLink1",
                              })}
                            </a>{" "}
                            {formatMessage({
                              id: "pages.first.access.cmp.mn.idx.terms2",
                            })}{" "}
                            <a
                              target="_blank"
                              href="https://unpartnerportalcso.zendesk.com/hc/en-us/article_attachments/360017929314/UN_Partner_Portal_Privacy_Policy_.pdf"
                              alt={formatMessage({
                                id: "pages.first.access.cmp.mn.idx.termsLink2",
                              })}
                              rel="noreferrer"
                            >
                              {formatMessage({
                                id: "pages.first.access.cmp.mn.idx.termsLink2",
                              })}
                            </a>{" "}
                            {formatMessage({
                              id: "pages.first.access.cmp.mn.idx.terms3",
                            })}
                          </>
                        }
                      />
                    </FieldGroup>
                  </div>
                  <div className={style.actionsWrapper}>
                    <Button
                      variant="text"
                      text={formatMessage({
                        id: "pages.first.access.cmp.mn.idx.back",
                      })}
                      onClick={onClickPreviousStep}
                    />
                    <Button
                      isDisabled={formValues?.term_of_use !== true}
                      text={formatMessage({
                        id: "pages.first.access.cmp.mn.idx.register",
                      })}
                      onClick={onClickNextStep}
                    />
                  </div>
                </StepContent>
              </Step>
            </Stepper>
          </div>
        </>
      );
    case "welcome-confirmation":
      return (
        <>
          <div className={style.sectionWrapper}>
            <span className={style.description}>
              {formatMessage({
                id: "pages.first.access.cmp.mn.idx.sentRequestLabel",
              })}{" "}
              <b>{fullName}</b>
            </span>
            <Button
              text={formatMessage({
                id: "pages.first.access.cmp.mn.idx.sendNewRequest",
              })}
              onClick={onClickSendNewRequest}
            />
          </div>
        </>
      );
    case "welcome-agency":
      return (
        <>
          <div className={style.sectionWrapper}>
            <span className={style.firstDescription}>{formatMessage({ id: "pages.first.access.cmp.mn.idx.helpInfo" })}</span>
            <FieldGroup>
              <Field
                component={FieldAutosuggest}
                validate={[isRequired]}
                id=""
                name=""
                label={formatMessage({
                  id: "pages.first.access.cmp.mn.idx.countryOffices",
                })}
                placeholder={formatMessage({
                  id: "pages.first.access.cmp.mn.idx.countryOffices",
                })}
                fetchFunction={[]}
                valueField="value"
                labelField="label"
              />
              <Button
                text={formatMessage({
                  id: "pages.first.access.cmp.mn.idx.registerOne",
                })}
                onClick={() => {}}
              />
            </FieldGroup>
          </div>
        </>
      );
    default:
      return <></>;
  }
};

const Main = ({
  onClickBack,
  currentStep,
  onClickContinueAsPartner,
  getPartnersBasicInformation,
  onClickRequestPartnerAccess,
  onClickCreateNewPartnerProfile,
  onClickCreateNewAgencyMember,
  fullName,
  onClickSendNewRequest,
  newPartnerCurrentStep,
  onClickNextStep,
  onClickPreviousStep,
  partnerTypes,
  countries,
  formValues,
  onChangeIsHq,
  onClickLogout,
  role,
  agencyCountryOffices,
}) => {
  const isYearBetween = useMemo(() => isBetween(1000, new Date().getFullYear()), []);

  return (
    <div
      className={style.mainWrapper}
      style={{
        backgroundImage: `linear-gradient(0deg, #2296F3BB, #2296F3BB), url("${imageBackground}")`,
      }}
    >
      <div className={style.stackWrapper}>
        <div>
          <LanguageTrigger />
        </div>
        <div>
          <IconButton size="large" edge="end" onClick={onClickLogout} color="inherit">
            <Logout />
          </IconButton>
        </div>
      </div>
      <div className={style.contentWrapper}>
        <div className={style.layoutWrapper}>
          <div className={style.boxWrapper}>
            <div className={style.headerWrapper}>
              {currentStep !== "welcome" && currentStep !== "agency-access" ? (
                <div>
                  <IconButton onClick={onClickBack}>
                    <ChevronLeftIcon style={{ fontSize: "1.5rem", color: "#222222" }} />
                  </IconButton>
                </div>
              ) : undefined}
              <div>
                <span className={style.title}>{formatMessage({ id: "pages.first.access.cmp.mn.idx.title" })}</span>
                {role === "partner" ? (
                  <span className={style.subtitle}>
                    {formatMessage({
                      id: "pages.first.access.cmp.mn.idx.subtitle",
                    })}
                  </span>
                ) : undefined}
              </div>
            </div>
            <div className={style.formWrapper}>
              {getContentByCurrentStep(
                currentStep,
                onClickContinueAsPartner,
                getPartnersBasicInformation,
                onClickRequestPartnerAccess,
                onClickCreateNewPartnerProfile,
                onClickCreateNewAgencyMember,
                fullName,
                onClickSendNewRequest,
                newPartnerCurrentStep,
                onClickNextStep,
                onClickPreviousStep,
                partnerTypes,
                countries,
                formValues,
                onChangeIsHq,
                isYearBetween,
                agencyCountryOffices,
              )}
            </div>
          </div>
          <div className={style.logosWrapper}>
            <div className={style.unppLogoWrapper}>
              <img
                alt={formatMessage({
                  id: "pages.first.access.cmp.mn.idx.logoAltText",
                })}
                src={imageLogo}
              />
            </div>
            <div>
              <div className={style.agenciesLogosWrapper}>
                <img alt="IOM" src={imageIom} />
                <img alt="UN" src={imageUn} />
                <img alt="UN WOMEN" src={imageUnwomen} />
                <img alt="FAO" src={imageFao} />
                <img alt="UNFPA" src={imageUnfpa} />
                <img alt="UNHCR" src={imageUnhcr} />
                <img alt="UNICEF" src={imageUnicef} />
                <img alt="UNOPS" src={imageUnops} />
                <img alt="WFP" src={imageWfp} />
                <img alt="WHO" src={imageWho} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default reduxForm({ form: "firstAccessForm", enableReinitialize: true })(Main);
