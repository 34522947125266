import { formatMessage } from "@local/legacy-utils/i18nHelper";
import GppGoodIcon from "@mui/icons-material/GppGood";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import FlagIcon from "@local/components/flag-icon";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import HourglassDisabledIcon from "@mui/icons-material/HourglassDisabled";
import { Divider, IconButton, TextField, Tooltip } from "@mui/material";
import { adaptDocumentsType } from "./adapters";
import { FLAG_TYPES } from "@local/utils/constants";
import DownloadIcon from "@local/components/download-icon";
import { AGENCY_RESTRICTIONS, isSectionVisible } from "@local/legacy-utils/restrictions";
import { FLAGS, UN_AGENCIES } from "@local/legacy-utils/constants";
import React from "react";
import { formatDate } from "../components/psea/utils";
import FieldFile from "@local/components/field-file";

import moment from "moment";
import i18n from "@local/i18next/i18n";

const getVerificationStatus = (Verification) => {
  if (Verification?.is_verified) {
    switch (Verification?.status) {
      case "expired":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ display: "flex", position: "relative" }}>
              <AddModeratorIcon style={{ fill: "#f44336", fontSize: "1.25rem" }} />
              <HourglassDisabledIcon
                style={{
                  fill: "#f44336",
                  position: "absolute",
                  background: "#fff",
                  borderRadius: "50%",
                  bottom: "0",
                  right: "0.03rem",
                  width: "0.75rem",
                  height: "0.75rem",
                }}
              />
            </span>
            <span>
              {formatMessage({
                id: "pages.partner.details.utils.columns.expired",
              })}
            </span>
          </div>
        );
      case "invalidated":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <RemoveModeratorIcon style={{ fill: "#1E90FF", fontSize: "1.25rem" }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.utils.columns.invalidated",
              })}
            </span>
          </div>
        );
      default:
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <GppGoodIcon
              style={{
                fontSize: "1.25rem",
                marginRight: ".25rem",
                color: "#099854",
              }}
            />
            <span>
              {formatMessage({
                id: "pages.partner.details.utils.columns.verified",
              })}
            </span>
          </div>
        );
    }
  } else {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <GppGoodIcon
          style={{
            fontSize: "1.25rem",
            marginRight: ".25rem",
            color: "#f44336",
          }}
        />
        <span>
          {formatMessage({
            id: "pages.partner.details.utils.columns.unverified",
          })}
        </span>
      </div>
    );
  }
};

export const getVerificationsColumns = () => [
  {
    id: "is_verified",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.isVerified",
    }),
    type: "custom",
    render: (row) => getVerificationStatus(row),
  },
  {
    id: "created",
    title: formatMessage({ id: "pages.partner.details.utils.columns.created" }),
    type: "date",
  },
  {
    id: "submitter.name",
    title: formatMessage({ id: "pages.partner.details.utils.columns.name" }),
    type: "custom",
    render: (row) => <span>{row?.submitter?.name ? row?.submitter?.name : "-"}</span>,
  },
  {
    id: "submitter.email",
    title: i18n.t("Email"),
    type: "custom",
    render: (row) => <span>{row?.submitter?.email ? row?.submitter?.email : "-"}</span>,
  },
  {
    id: "submitter.agency_name",
    title: i18n.t("Agency Name"),
    type: "custom",
    render: (row) => {
      const agencyName = row?.submitter?.agency_name;
      const agency = UN_AGENCIES.find((a) => a.value === agencyName);

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{agency ? agency.label : "-"}</span>
        </div>
      );
    },
  },
  {
    id: "submitter.name",
    title: "",
    type: "custom",
    render: (row) => (
      <span>
        {row?.submitter?.name ? (
          ""
        ) : (
          <Tooltip title={"System"}>
            <NewReleasesIcon sx={{ color: "#FFA500" }} />
          </Tooltip>
        )}
      </span>
    ),
  },
];

export const getVerificationsExpandSectionItems = () => [
  {
    id: "is_cert_uploaded",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.certUpload",
    }),
    getVisibility: (row) => row?.status !== "invalidated",
    type: "custom",
    render: (row) => (
      <>
        <span style={{ display: "block", marginBottom: ".25rem" }}>{row?.is_cert_uploaded ? formatMessage({ id: "pages.partner.details.utils.columns.yes" }) : formatMessage({ id: "pages.partner.details.utils.columns.no" })}</span>
        <span
          style={{
            display: "block",
            marginBottom: ".25rem",
            color: "#777777",
            fontSize: ".75rem",
          }}
        >
          {formatMessage({ id: "pages.partner.details.utils.columns.comment" })}
        </span>
        <span style={{ display: "block" }}>{row?.cert_uploaded_comment ?? "--"}</span>
      </>
    ),
  },
  {
    id: "is_reason_acceptable",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.missingReason",
    }),
    type: "custom",
    getVisibility: (row) => row?.is_cert_uploaded === false && row?.status !== "invalidated",
    render: (row) => (
      <>
        <span style={{ display: "block", marginBottom: ".25rem" }}>{row?.is_reason_acceptable ? formatMessage({ id: "pages.partner.details.utils.columns.yes" }) : formatMessage({ id: "pages.partner.details.utils.columns.no" })}</span>
        <span
          style={{
            display: "block",
            marginBottom: ".25rem",
            color: "#777777",
            fontSize: ".75rem",
          }}
        >
          {formatMessage({ id: "pages.partner.details.utils.columns.comment" })}
        </span>
        <span style={{ display: "block" }}>{row?.reason_acceptable_comment ?? "--"}</span>
      </>
    ),
  },
  {
    id: "is_mm_consistent",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.mmConsistent",
    }),
    getVisibility: (row) => row?.status !== "invalidated",
    type: "custom",
    render: (row) => (
      <>
        <span style={{ display: "block", marginBottom: ".25rem" }}>{row?.is_mm_consistent ? formatMessage({ id: "pages.partner.details.utils.columns.yes" }) : formatMessage({ id: "pages.partner.details.utils.columns.no" })}</span>
        <span
          style={{
            display: "block",
            marginBottom: ".25rem",
            color: "#777777",
            fontSize: ".75rem",
          }}
        >
          {formatMessage({ id: "pages.partner.details.utils.columns.comment" })}
        </span>
        <span style={{ display: "block" }}>{row?.mm_consistent_comment ?? "--"}</span>
      </>
    ),
  },
  {
    id: "is_indicate_results",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.indicateResults",
    }),
    getVisibility: (row) => row?.status !== "invalidated",
    type: "custom",
    render: (row) => (
      <>
        <span style={{ display: "block", marginBottom: ".25rem" }}>{row?.is_indicate_results ? formatMessage({ id: "pages.partner.details.utils.columns.yes" }) : formatMessage({ id: "pages.partner.details.utils.columns.no" })}</span>
        <span
          style={{
            display: "block",
            marginBottom: ".25rem",
            color: "#777777",
            fontSize: ".75rem",
          }}
        >
          {formatMessage({ id: "pages.partner.details.utils.columns.comment" })}
        </span>
        <span style={{ display: "block" }}>{row?.indicate_results_comment ?? "--"}</span>
      </>
    ),
  },
  {
    id: "is_rep_risk",
    title: formatMessage({ id: "pages.partner.details.utils.columns.repRisk" }),
    type: "custom",
    getVisibility: (row) => row?.status !== "invalidated",
    render: (row) => (
      <>
        <span style={{ display: "block", marginBottom: ".25rem" }}>{row?.is_rep_risk ? formatMessage({ id: "pages.partner.details.utils.columns.yes" }) : formatMessage({ id: "pages.partner.details.utils.columns.no" })}</span>
        <span
          style={{
            display: "block",
            marginBottom: ".25rem",
            color: "#777777",
            fontSize: ".75rem",
          }}
        >
          {formatMessage({ id: "pages.partner.details.utils.columns.comment" })}
        </span>
        <span style={{ display: "block" }}>{row?.rep_risk_comment ?? "--"}</span>
      </>
    ),
  },
  {
    id: "is_yellow_flag",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.yellowFlag",
    }),
    getVisibility: (row) => row?.status !== "invalidated",
    type: "custom",
    render: (row) => (
      <>
        <span style={{ display: "block", marginBottom: ".25rem" }}>{row?.is_yellow_flag ? formatMessage({ id: "pages.partner.details.utils.columns.yes" }) : formatMessage({ id: "pages.partner.details.utils.columns.no" })}</span>
        <span
          style={{
            display: "block",
            marginBottom: ".25rem",
            color: "#777777",
            fontSize: ".75rem",
          }}
        >
          {formatMessage({ id: "pages.partner.details.utils.columns.comment" })}
        </span>
        <span style={{ display: "block" }}>{row?.yellow_flag_comment ?? "--"}</span>
      </>
    ),
  },
  {
    id: "can_verify",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.canVerify",
    }),
    type: "custom",
    getVisibility: (row) => row?.is_yellow_flag === true && row?.status !== "invalidated",
    render: (row) => (
      <>
        <span style={{ display: "block", marginBottom: ".25rem" }}>{row?.can_verify ? formatMessage({ id: "pages.partner.details.utils.columns.yes" }) : formatMessage({ id: "pages.partner.details.utils.columns.no" })}</span>
        <span
          style={{
            display: "block",
            marginBottom: ".25rem",
            color: "#777777",
            fontSize: ".75rem",
          }}
        >
          {formatMessage({ id: "pages.partner.details.utils.columns.comment" })}
        </span>
        <span style={{ display: "block" }}>{row?.can_verify_comment ?? "--"}</span>
      </>
    ),
  },
  {
    id: "changes",
    title: "Changes that invalidated partner's profile status",
    type: "custom",
    getVisibility: (row) => row?.status === "invalidated",
    render: (row) => (
      <>
        {row.status === "invalidated" ? (
          <span
            style={{
              display: "block",
            }}
          >
            {row?.logs?.map((log, index) => (
              <>
                {renderVerificationSection(log)} {Object.keys(log.old_data).length > 0 && row.logs.length !== index + 1 && Object.keys(log.new_data).length > 0 && <Divider style={{ marginBottom: "1.5rem", marginTop: "1.5rem" }} />}
              </>
            ))}
          </span>
        ) : (
          "--"
        )}
      </>
    ),
  },
];

const renderVerificationSection = (log) => {
  const keysToLabels = {
    ethic_safeguard_comment: i18n.t("Briefly describe the organization's mechanisms to safeguard against the violation and abuse of beneficiaries, including sexual exploitation and abuse"), // multiline
    ethic_safeguard: i18n.t("Are these mechanisms formally documented in an organizational policy or code of conduct?"),
    ethic_safeguard_policy: i18n.t("Copy of your policy or code of conduct") + " (" + i18n.t("Safeguard policy") + ")",
    ethic_fraud_comment: i18n.t("Briefly describe the organization's mechanisms to safeguard against fraud, corruption and other unethical behavior"), // multiline
    ethic_fraud: i18n.t("Are these mechanisms formally documented in an organizational policy or code of conduct?"),
    ethic_fraud_policy: i18n.t("Copy of your policy or code of conduct") + " (" + i18n.t("Fraud policy") + ")",
    social_and_environmental_standards: i18n.t(`Does the partner have policies or practices in place to avoid adverse social and environmental impacts and to 
    demonstrate commitments to implementing work plan activities in a socially and environmentally sustainable manner in line with the UN Model Approach to Social and Environmental Standards?`), //boolean
    mandate_and_mission: i18n.t("Briefly describe the mandate and mission of the organization"), // multiline
    registered_to_operate_in_country: i18n.t("Is organization registered to operate in the country of origin?"),
    document: i18n.t("Registration document"),
    issue_date: i18n.t("Registration date"),
    issuing_authority: i18n.t("Issuing authority"),
    registration_number: i18n.t("Registration number"),
    created_by: i18n.t("Created by"),
  };

  // array of field names that are multiline
  const multilineKeys = ["ethic_safeguard_comment", "ethic_fraud_comment", "mandate_and_mission"];
  const fileKeys = ["document", "ethic_fraud_policy", "ethic_safeguard_policy"];

  // check if a key is multiline
  const checkForMultiline = (key) => multilineKeys.includes(key);
  const checkForFiles = (key) => fileKeys.includes(key);

  const sectionLabel = {
    "Mandate and Mision": i18n.t("Mandate and Mission"),
    Identification: i18n.t("Identification"),
    // Map other section identifiers to labels as needed
  }[log.section];

  // If sectionLabel is not found, don't render the section
  if (!sectionLabel || (!log?.old_date && !log?.new_data)) return null;

  // parse value based on type (boolean, date, string)
  const parseField = (value) => {
    if (typeof value === "boolean") {
      return value ? i18n.t("Yes") : i18n.t("No");
    }

    if (moment(value, true).isValid()) {
      return formatDate(value);
    }
    return value;
  };

  return (
    <>
      {Object.keys(log.old_data)?.length > 0 && (
        <div style={{ marginTop: "1rem", backgroundColor: "white", borderRadius: "1rem", padding: "1.5rem", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
          {Object.keys(log.old_data)?.length > 0 && Object.keys(log.new_data).length > 0 && (
            <div style={{ fontSize: "1.25rem", marginBottom: "1rem", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              {sectionLabel}
              <span style={{ fontSize: "1rem" }}> Changed on: {formatDate(log.created)}</span>
            </div>
          )}
          <div>
            {Object.keys(log.old_data).map((key) => (
              <React.Fragment key={key}>
                <div style={{ marginTop: "1rem" }}>
                  <span>{keysToLabels[key] || key}</span>

                  <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                    {checkForFiles(key) ? (
                      <div style={{ display: "flex", flexDirection: "row", gap: "1rem", width: "100%" }}>
                        <div style={{ width: "50%" }}>
                          {log.old_data[key] ? (
                            <FieldFile
                              id="document_from"
                              margin="normal"
                              name="document_from"
                              input={{
                                name: log.old_data[key] ? log.old_data[key]?.split("/").at(-1) : null,
                                value: { filename: log.old_data[key] ? log.old_data[key]?.split("/").at(-1) : null, file_field: log.old_data[key] ? log.old_data[key] : null },
                              }}
                              isLocalFile={true}
                              readOnly={true}
                              label={i18n.t("From")}
                            />
                          ) : (
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                              <span style={{ display: "flex" }}>{i18n.t("No previous file uploaded")}</span>
                            </div>
                          )}
                        </div>
                        <div style={{ width: "50%" }}>
                          <FieldFile
                            id="document_to"
                            margin="normal"
                            name="document_to"
                            input={{
                              name: log.new_data[key]?.split("/").at(-1),
                              value: { filename: log.new_data[key]?.split("/").at(-1), file_field: log.new_data[key] },
                            }}
                            isLocalFile={true}
                            readOnly={true}
                            label={i18n.t("To")}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <TextField multiline={checkForMultiline(key)} rows={checkForMultiline(key) ? 5 : 1} fullWidth margin="normal" disabled label={i18n.t("From")} value={parseField(log.old_data[key])} />
                        <TextField multiline={checkForMultiline(key)} rows={checkForMultiline(key) ? 5 : 1} fullWidth margin="normal" disabled label={i18n.t("To")} value={parseField(log.new_data[key])} />
                      </>
                    )}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export const getObservationsColumns = (hasResolveEscalatePermission, hasReviewSanctionMatchPermission, userId, onClickOpenUpdateObservationModal, onClickOpenUpdateEscalatedObservationModal, onClickOpenUpdateSanctionObservationModal) => [
  {
    id: "flag_type",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.flagType",
    }),
    type: "custom",
    render: (row) => <FlagIcon flagType={row.flag_type} isValid={row.is_valid} isEscalated={row.escalation_comment === true} category={row.category} />,
  },
  {
    id: "category_display",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.categoryDisplay",
    }),
    type: "text",
  },
  {
    id: "modified",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.modified",
    }),
    type: "date",
  },
  {
    id: "submitter",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.submitter",
    }),
    type: "custom",
    render: (row) => (
      <span>
        {row?.submitter?.name}, {row?.submitter?.agency_name}
      </span>
    ),
  },
  {
    id: "update_observation",
    title: "",
    type: "custom",
    render: (row) => (
      <div>
        {row?.submitter && userId === row?.submitter?.id && row?.flag_type === FLAGS.YELLOW && row?.is_valid !== false ? (
          <IconButton onClick={() => onClickOpenUpdateObservationModal(row)}>
            <EditIcon />
          </IconButton>
        ) : hasResolveEscalatePermission && row?.flag_type === FLAGS.ESCALATED ? (
          <IconButton onClick={() => onClickOpenUpdateEscalatedObservationModal(row)}>
            <EditIcon />
          </IconButton>
        ) : hasReviewSanctionMatchPermission && row?.category === FLAGS.SANCTION && row?.is_valid === null ? (
          <IconButton onClick={() => onClickOpenUpdateSanctionObservationModal(row)}>
            <EditIcon />
          </IconButton>
        ) : undefined}
      </div>
    ),
  },
];

export const getObservationsExpandSectionItems = () => [
  {
    id: "created",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.createdOne",
    }),
    type: "date",
  },
  {
    id: "comment",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.commentOne",
    }),
  },
  {
    id: "contact",
    title: formatMessage({ id: "pages.partner.details.utils.columns.contact" }),
    getVisibility: (row) => row?.category !== FLAG_TYPES.SANCTION,
  },
  {
    id: "telephone",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.telephone",
    }),
    getVisibility: (row) => row?.category !== FLAG_TYPES.SANCTION,
  },
  {
    id: "email",
    title: formatMessage({ id: "pages.partner.details.utils.columns.email" }),
    getVisibility: (row) => row?.category !== FLAG_TYPES.SANCTION,
  },
  {
    id: "attachment",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.attachment",
    }),
    getVisibility: (row) => row?.category !== FLAG_TYPES.SANCTION,
    type: "custom",
    render: (row) => (row?.attachment ? <DownloadIcon fileUrl={row?.attachment?.file_field} fileName={row?.attachment?.name} withMarginTop /> : <span>--</span>),
  },
  {
    id: "reason",
    title: formatMessage({ id: "pages.partner.details.utils.columns.reason" }),
    getVisibility: (row) => row?.validation_comment ?? false,
  },
  {
    id: "reasonEscalation",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.reasonEscalation",
    }),
    getVisibility: (row) => row?.escalation_comment ?? false,
  },
];

export const getApplicationsColumns = () => [
  {
    id: "eoi.title",
    title: formatMessage({ id: "pages.partner.details.utils.columns.title" }),
    type: "text",
  },
  {
    id: "id",
    title: formatMessage({ id: "pages.partner.details.utils.columns.id" }),
    type: "link",
    to: (row) => `/cfei/${row?.is_direct ? "direct" : "open"}/${row?.eoi?.id}/applications/${row?.id}`,
  },
  {
    id: "cfei_type",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.cfeiType",
    }),
    type: "text",
  },
  {
    id: "eoi.agency.name",
    title: formatMessage({ id: "pages.partner.details.utils.columns.nameOne" }),
    type: "text",
  },
  {
    id: "country",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.countryCode",
    }),
    type: "text",
  },
  {
    id: "specializations",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.specializations",
    }),
    type: "text",
  },
  {
    id: "created",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.createdTwo",
    }),
    type: "date",
  },
  {
    id: "did_win",
    title: formatMessage({ id: "pages.partner.details.utils.columns.didWin" }),
    type: "custom",
    render: (row) =>
      row.did_win ? (
        <CheckIcon
          style={{
            fontSize: "1.25rem",
            marginRight: ".25rem",
            color: "#099854",
          }}
        />
      ) : (
        <CloseIcon
          style={{
            fontSize: "1.25rem",
            marginRight: ".25rem",
            color: "#aaaaaa",
          }}
        />
      ),
  },
];

export const getUsersColumns = () => [
  {
    id: "user.fullname",
    title: formatMessage({ id: "pages.partner.details.utils.columns.nameTwo" }),
    type: "text",
  },
  {
    id: "title",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.titleOne",
    }),
    type: "text",
  },
  {
    id: "role",
    title: formatMessage({ id: "pages.partner.details.utils.columns.role" }),
    type: "text",
  },
  {
    id: "user.email",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.emailOne",
    }),
    type: "text",
  },
];

export const getUnDocumentColumns = (onClickManageUnDocument, onClickDeleteUnDocument, session) => [
  {
    id: "document_type",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.documentType",
    }),
    type: "custom",
    render: (row) => <span>{adaptDocumentsType(row?.document_type)?.label}</span>,
  },
  {
    id: "start_date",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.startDate",
    }),
    type: "date",
  },
  {
    id: "end_date",
    title: formatMessage({ id: "pages.partner.details.utils.columns.endDate" }),
    type: "date",
  },
  {
    id: "file_field",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.fileField",
    }),
    type: "custom",
    render: (row) => <DownloadIcon fileUrl={row?.file_field?.file_field} fileName={row?.file_field?.name} withMarginTop />,
  },
  {
    id: "created_by",
    title: formatMessage({
      id: "pages.partner.details.utils.columns.createdBy",
    }),
  },
  {
    id: "edit_delete",
    type: "custom",
    render: (row) => (
      <div>
        <IconButton onClick={() => onClickManageUnDocument(row)}>
          <EditIcon />
        </IconButton>
        {isSectionVisible(AGENCY_RESTRICTIONS.partners.unData.documentDelete, session) && (
          <IconButton onClick={() => onClickDeleteUnDocument(row)}>
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    ),
  },
];
