/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import Card from "@local/components/card";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { ROLES, PROJECT_TYPES } from "@local/legacy-utils/constants";
import { getOpenProjectItems, getDirectProjectItems, getUnsolicitedProjectItems, getPartnerProjectItems } from "../../utils/items";
import { getSectorsList, getAgenciesList } from "@local/actions";
import ConceptNote from "./components/concept-note";
import SelectionCriteria from "./components/selection-criteria";
import AgencyClarificationAnswers from "./components/agency-clarification-answers";
import PartnerClarificationRequests from "./components/partner-clarification-requests";
import InformedPartners from "./components/informed-partners";
import SelectedPartners from "./components/selected-partners";
import SelectedPartnersDirect from "./components/selected-partners-direct";
import SelectedPartnerJustification from "./components/selected-partner-justification";
import style from "./style.module.scss";
import Timeline from "./components/timeline";
import { getSortedList } from "@local/utils";
import { adaptJustifications } from "@local/utils/adapters";
import { mapAgenciesIds } from "@local/utils/constants";

const Overview = ({
  project,
  role,
  conceptNote,
  isCompleted,
  cn_template,
  displayGoal,
  loading,
  agency,
  agencyId,
  sectors,
  agencies,
  getSectors,
  organizationTypes,
  currentStatus,
  projectStatus,
  isProjectPublished,
  isUploadFileModalVisible,
  isRemoveFileModalVisible,
  isPreviewModalVisible,
  onClickOpenPreviewModal,
  onClickClosePreviewModal,
  getClarificationRequests,
  isClarificationDeadlinePassed,
  requestsLoading,
  requestCount,
  hasPermissionToAddRequest,
  handleClickOpenAddRequestMenu,
  handleCloseAddRequestMenu,
  openAddRequestMenu,
  anchorEl,
  isAddClarificationRequestModalVisible,
  onClickOpenAddClarificationRequestModal,
  onClickCloseAddClarificationRequestModal,
  onClickAddClarificationRequest,
  onClickOpenUploadFileModal,
  onClickCloseUploadFileModal,
  onClickOpenRemoveFileModal,
  onClickCloseRemoveFileModal,
  onClickAddClarificationAnswer,
  onClickDeleteClarificationAnswer,
  getAgencies,
  isApplicationDeadlinePassed,
  isCreator,
  isFocalPoint,
  justifications,
}) => {
  const { type, id } = useParams();
  const history = useHistory();

  useEffect(() => {
    getSectors();
    getAgencies();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        {type === PROJECT_TYPES.OPEN && !loading && project?.deadline_date ? (
          <Grid item xs={12}>
            <Timeline project={project} />
          </Grid>
        ) : undefined}
        <Grid item xs={8}>
          <Card
            title={
              <div className={style.headerWrapper}>
                <span className={style.headerTitle}>
                  {formatMessage({
                    id: "pages.project.details.cmp.overview.idx.projectDetailsTitle",
                  })}
                </span>
                <div className={style.projectIdWrapper}>
                  <span className={style.projectIdLabel}>
                    {type === PROJECT_TYPES.OPEN || type === PROJECT_TYPES.PINNED
                      ? `${formatMessage({
                          id: "pages.project.details.cmp.overview.idx.id",
                        })} CFEI/`
                      : type !== PROJECT_TYPES.UNSOLICITED
                        ? `${formatMessage({
                            id: "pages.project.details.cmp.overview.idx.dsrId",
                          })} DSP/`
                        : ""}
                  </span>
                  <span className={style.projectIdValue}>{project?.displayID}</span>
                </div>
              </div>
            }
          >
            <div>
              {role === ROLES.AGENCY && type === PROJECT_TYPES.OPEN ? (
                getOpenProjectItems(project, sectors, agencies, displayGoal).map((item, index) =>
                  item.isVisible ? (
                    <div key={`open_project_details_${id}_${item.id}_${index}`}>
                      <span className={style.itemLabel}>{item.label}</span>
                      {item.type === "custom" ? item.value() : <span className={style.itemValue}>{item.value || "-"}</span>}
                    </div>
                  ) : (
                    <></>
                  ),
                )
              ) : (
                <></>
              )}
              {role === ROLES.AGENCY && type === PROJECT_TYPES.DIRECT ? (
                getDirectProjectItems(project, sectors, agencies, displayGoal, justifications).map((item, index) =>
                  item.isVisible ? (
                    <div key={`direct_project_details_${id}_${item.id}_${index}`}>
                      <span className={style.itemLabel}>{item.label}</span>
                      {item.type === "custom" ? item.value() : <span className={style.itemValue}>{item.value || "-"}</span>}
                    </div>
                  ) : (
                    <></>
                  ),
                )
              ) : (
                <></>
              )}
              {type === PROJECT_TYPES.UNSOLICITED ? (
                getUnsolicitedProjectItems(project, sectors, role, history, project?.partner?.id, organizationTypes).map((item, index) =>
                  item.isVisible ? (
                    <div key={`unsolicited_project_details_${id}_${item.id}_${index}`}>
                      <span className={style.itemLabel}>{item.label}</span>
                      {item.type === "custom" ? item.value() : <span className={style.itemValue}>{item.value || "-"}</span>}
                    </div>
                  ) : (
                    <></>
                  ),
                )
              ) : (
                <></>
              )}
              {role === ROLES.PARTNER && type !== PROJECT_TYPES.UNSOLICITED ? (
                getPartnerProjectItems(project, sectors, displayGoal, justifications).map((item, index) =>
                  item.isVisible ? (
                    <div key={`partner_project_details_${id}_${item.id}_${index}`}>
                      <span className={style.itemLabel}>{item.label}</span>
                      {item.type === "custom" ? item.value() : <span className={style.itemValue}>{item.value || "-"}</span>}
                    </div>
                  ) : (
                    <></>
                  ),
                )
              ) : (
                <></>
              )}
            </div>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            {role === ROLES.PARTNER && type === PROJECT_TYPES.OPEN ? (
              <Grid item xs={12}>
                <ConceptNote
                  title={formatMessage({
                    id: "pages.project.details.cmp.overview.idx.cnTemplate",
                  })}
                  conceptNote={cn_template}
                />
              </Grid>
            ) : (
              <></>
            )}
            {type === PROJECT_TYPES.UNSOLICITED ? (
              <Grid item xs={12}>
                <ConceptNote
                  title={formatMessage({
                    id: "pages.project.details.cmp.overview.idx.cn",
                  })}
                  conceptNote={conceptNote?.file_field}
                />{" "}
              </Grid>
            ) : (
              <></>
            )}
            {type === PROJECT_TYPES.OPEN && role === ROLES.PARTNER ? (
              <Grid item xs={12}>
                <AgencyClarificationAnswers
                  isUploadFileModalVisible={isUploadFileModalVisible}
                  isRemoveFileModalVisible={isRemoveFileModalVisible}
                  onClickOpenUploadFileModal={onClickOpenUploadFileModal}
                  onClickCloseUploadFileModal={onClickCloseUploadFileModal}
                  onClickOpenRemoveFileModal={onClickOpenRemoveFileModal}
                  onClickCloseRemoveFileModal={onClickCloseRemoveFileModal}
                  onClickAddClarificationAnswer={onClickAddClarificationAnswer}
                  onClickDeleteClarificationAnswer={onClickDeleteClarificationAnswer}
                  isClarificationDeadlinePassed={isClarificationDeadlinePassed}
                  isCreator={isCreator}
                  isFocalPoint={isFocalPoint}
                />
              </Grid>
            ) : (
              <></>
            )}
            {type === PROJECT_TYPES.OPEN && role === ROLES.PARTNER ? (
              <Grid item xs={12}>
                <PartnerClarificationRequests
                  isPreviewModalVisible={isPreviewModalVisible}
                  onClickOpenPreviewModal={onClickOpenPreviewModal}
                  onClickClosePreviewModal={onClickClosePreviewModal}
                  getClarificationRequests={getClarificationRequests}
                  isClarificationDeadlinePassed={isClarificationDeadlinePassed}
                  requestsLoading={requestsLoading}
                  requestCount={requestCount}
                  hasPermissionToAddRequest={hasPermissionToAddRequest}
                  handleClickOpenAddRequestMenu={handleClickOpenAddRequestMenu}
                  handleCloseAddRequestMenu={handleCloseAddRequestMenu}
                  openAddRequestMenu={openAddRequestMenu}
                  anchorEl={anchorEl}
                  isAddClarificationRequestModalVisible={isAddClarificationRequestModalVisible}
                  onClickOpenAddClarificationRequestModal={onClickOpenAddClarificationRequestModal}
                  onClickCloseAddClarificationRequestModal={onClickCloseAddClarificationRequestModal}
                  onClickAddClarificationRequest={onClickAddClarificationRequest}
                  isApplicationDeadlinePassed={isApplicationDeadlinePassed}
                />
              </Grid>
            ) : (
              <></>
            )}
            {type === PROJECT_TYPES.OPEN && role === ROLES.AGENCY ? (
              <Grid item xs={12}>
                <AgencyClarificationAnswers
                  isClarificationDeadlinePassed={isClarificationDeadlinePassed}
                  isUploadFileModalVisible={isUploadFileModalVisible}
                  isRemoveFileModalVisible={isRemoveFileModalVisible}
                  onClickOpenUploadFileModal={onClickOpenUploadFileModal}
                  onClickCloseUploadFileModal={onClickCloseUploadFileModal}
                  onClickOpenRemoveFileModal={onClickOpenRemoveFileModal}
                  onClickCloseRemoveFileModal={onClickCloseRemoveFileModal}
                  onClickAddClarificationAnswer={onClickAddClarificationAnswer}
                  onClickDeleteClarificationAnswer={onClickDeleteClarificationAnswer}
                  isApplicationDeadlinePassed={isApplicationDeadlinePassed}
                  isCreator={isCreator}
                  isFocalPoint={isFocalPoint}
                />
              </Grid>
            ) : (
              <></>
            )}
            {type === PROJECT_TYPES.OPEN ? (
              <Grid item xs={12}>
                <SelectionCriteria project={project} />
              </Grid>
            ) : (
              <></>
            )}
            {role === ROLES.AGENCY && type === PROJECT_TYPES.OPEN ? (
              <Grid item xs={12}>
                <InformedPartners project={project} />
              </Grid>
            ) : (
              <></>
            )}
            {role === ROLES.AGENCY && type === PROJECT_TYPES.DIRECT ? (
              <Grid item xs={12}>
                <SelectedPartnersDirect project={project} currentStatus={currentStatus} projectStatus={projectStatus} isProjectPublished={isProjectPublished} />
              </Grid>
            ) : (
              <></>
            )}
            {role === ROLES.AGENCY && type === PROJECT_TYPES.DIRECT && isCompleted ? (
              <Grid item xs={12}>
                <SelectedPartnerJustification project={project} />
              </Grid>
            ) : (
              <></>
            )}
            {role === ROLES.AGENCY && type === PROJECT_TYPES.OPEN && isCompleted && agency !== agencyId ? (
              <Grid item xs={12}>
                <SelectedPartners project={project} />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  sectors: getSortedList(state?.sectorsList?.data?.data, "name"),
  organizationTypes: state?.partnerProfileConfig["partner-type"] ?? [],
  agencies: mapAgenciesIds(state?.agenciesList?.data?.data?.results) ?? [],
  justifications: adaptJustifications(state.partnerProfileConfig["direct-justifications"]),
});

const mapDispatchToProps = (dispatch) => ({
  getSectors: (params) => dispatch(getSectorsList(params)),
  getAgencies: (params) => dispatch(getAgenciesList(params)),
});

Overview.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
