import { formatDateToBeSent } from "@local/utils";
import { PROJECT_TYPES } from "@local/utils/constants";

export const adaptProjectToBeSent = (type, formValues, selectedLocations, attachments, criteria, agencyId, agencyOfficeId, project, onEditId) => {
  const locations = selectedLocations.map((location) => ({
    admin_level_1: {
      name: location.adminLevelOne.name,
      country_code: location.adminLevelOne.countryCode,
    },
    lat: location.latitude,
    lon: location.longitude,
  }));
  const result = {
    agency: agencyId,
    agency_office: agencyOfficeId,
    title: formValues.title,
    country_code: formValues.countries, // POST_REFACTORING_TODO: This was in the original request, not sure if it is needed
    locations: locations, // POST_REFACTORING_TODO: This was in the original request, not sure if it is needed
    countries: formValues.countries.map((country) => ({
      country,
      locations: locations.filter((location) => location.admin_level_1.country_code === country),
    })),
    focal_points: formValues?.focal_points ?? undefined,
    specializations: formValues.areas,
    population: formValues?.population ?? undefined,
    description: formValues.description,
    goal: formValues.goal,
    indicative_budget: formValues?.indicative_budget ?? undefined,
    indicative_budget_currency: formValues?.indicative_budget_currency ?? undefined,
    other_information: formValues?.other_information ?? undefined,
    start_date: formatDateToBeSent(formValues.start_date),
    end_date: formatDateToBeSent(formValues.end_date),
    attachments: attachments
      .filter((item) => formValues[`attachment_${item.uniqueId}`]?.id)
      .map((item) => ({
        id: item?.originalId ?? undefined,
        file: formValues[`attachment_${item.uniqueId}`].id,
        description: formValues[`attachment_description_${item.uniqueId}`],
      })),
    cn_template_id: formValues?.cn_template?.id,
    is_cn_substitute: formValues?.is_cn_substitute?.toString() === "true",
  };
  switch (type) {
    case PROJECT_TYPES.OPEN:
      return {
        ...result,
        clarification_request_deadline_date: formatDateToBeSent(formValues.clarification_request_deadline_date),
        deadline_date: formatDateToBeSent(formValues.deadline_date),
        notif_results_date: formatDateToBeSent(formValues.notif_results_date),
        has_weighting: formValues.has_weighting?.toString() === "true",
        assessments_criteria: criteria.map((item) => ({
          id: item?.originalId ?? undefined,
          selection_criteria: formValues[`selection_criteria_${item.uniqueId}`],
          description: formValues[`selection_criteria_description_${item.uniqueId}`],
          weight: formValues.has_weighting?.toString() === "true" ? parseFloat(formValues[`selection_criteria_weight_${item.uniqueId}`]) : undefined, // POST_REFACTORING_TODO Check this
        })),
      };
    case PROJECT_TYPES.UNSOLICITED:
      return {
        ...result,
        cn: project?.cn?.id !== formValues?.cn?.id ? formValues?.cn?.id : undefined,
        agency: formValues?.agency,
      };
    case PROJECT_TYPES.DIRECT:
      if (!onEditId) {
        return {
          applications: [
            {
              ds_attachment: formValues?.ds_attachment?.id,
              ds_justification_select: formValues?.ds_justification_select,
              is_cn_required: formValues?.is_cn_required === "true",
              justification_reason: formValues?.justification_reason,
              partner: formValues?.partner?.id,
            },
          ],
          eoi: result,
        };
      } else {
        return {
          ...result,
          applications: [
            {
              ds_attachment: formValues?.ds_attachment?.id,
              ds_justification_select: formValues?.ds_justification_select,
              is_cn_required: formValues?.is_cn_required === "true",
              justification_reason: formValues?.justification_reason,
              partner: formValues?.partner?.id,
            },
          ],
        };
      }
    default:
      return result;
  }
};
