import * as R from "ramda";

export const checkPermission = (permission, state) => R.includes(permission, state.session.permissions);

export const isRoleOffice = (role, state) => role === state.session.officeRole;

export const AGENCY_ROLES = {
  GLOBAL_EDITOR: "Global Editor",
  ADMINISTRATOR: "Administrator",
  HQ_EDITOR: "HQ Editor",
  READER: "Reader",
  EDITOR_BASIC: "Basic Editor",
  EDITOR_ADVANCED: "Advanced Editor",
  EDITOR_ADVANCED_PLUS: "Advanced Editor Plus",
  PAM_USER: "PAM USER",
  MFT_USER: "MFT USER",
  EDITOR_ADVANCED_SECRETARIAT: "UN Secretariat Advanced Editor",
};

export const PARTNER_PERMISSIONS = {
  REGISTER: "REGISTER",
  VIEW_DASHBOARD: "VIEW_DASHBOARD",
  CREATE_COUNTRY_OFFICE: "CREATE_COUNTRY_OFFICE",

  EDIT_PROFILE: "EDIT_PROFILE",
  EDIT_HQ_PROFILE: "EDIT_HQ_PROFILE",

  // Applications
  CFEI_VIEW: "CFEI_VIEW", // ALL USERS HAVE ACCESS
  CFEI_PINNING: "CFEI_PINNING",
  CFEI_SEND_CLARIFICATION_REQUEST: "CFEI_SEND_CLARIFICATION_REQUEST",
  CFEI_SUBMIT_CONCEPT_NOTE: "CFEI_SUBMIT_CONCEPT_NOTE",
  CFEI_VIEW_CONCEPT_NOTE: "CFEI_VIEW_CONCEPT_NOTE", // ALL USERS HAVE ACCESS
  CFEI_ANSWER_SELECTION: "CFEI_ANSWER_SELECTION",

  // Unsolicited Concept Notes
  UCN_VIEW: "UCN_VIEW", // ALL USERS HAVE ACCESS
  UCN_DRAFT: "UCN_DRAFT",
  UCN_EDIT: "UCN_EDIT",
  UCN_SUBMIT: "UCN_SUBMIT",
  UCN_DELETE: "UCN_DELETE",

  // Direct Selection and Retention
  DSR_VIEW: "DSR_VIEW", // ALL USERS HAVE ACCESS
  DSR_ANSWER: "DSR_ANSWER",

  // PSEA

  VIEW_PSEA_INITIAL_ASSESSMENT: "VIEW_PSEA_INITIAL_ASSESSMENT",
  EDIT_PSEA_INITIAL_ASSESSMENT: "EDIT_PSEA_INITIAL_ASSESSMENT",
  VIEW_PSEA_SELF_ASSESSMENT: "VIEW_PSEA_SELF_ASSESSMENT",
  EDIT_PSEA_SELF_ASSESSMENT: "EDIT_PSEA_SELF_ASSESSMENT",
  VIEW_PSEA_SELF_ASSESSMENT_RATING: "VIEW_PSEA_SELF_ASSESSMENT_RATING",
  VIEW_PSEA_SELF_ASSESSMENT_DOCUMENTS: "VIEW_PSEA_SELF_ASSESSMENT_DOCUMENTS",
  EDIT_PSEA_SELF_ASSESSMENT_DOCUMENTS: "EDIT_PSEA_SELF_ASSESSMENT_DOCUMENTS",
  VIEW_PSEA_SELF_ASSESSMENT_SNAPSHOTS: "VIEW_PSEA_SELF_ASSESSMENT_SNAPSHOTS",
  UNLOCK_PSEA_SELF_ASSESSMENT_PARTNER_SECTION: "UNLOCK_PSEA_SELF_ASSESSMENT_PARTNER_SECTION",
  SUBMIT_PSEA_SELF_ASSESSMENT: "SUBMIT_PSEA_SELF_ASSESSMENT",
  VIEW_PSEA_CSIP: "VIEW_PSEA_CSIP",
  DOWNLOAD_PSEA_CSIP_AS_RFT: "DOWNLOAD_PSEA_CSIP_AS_RFT",
  VIEW_PSEA_CSIP_ACTIVITIES: "VIEW_PSEA_CSIP_ACTIVITIES",
  VIEW_PSEA_CSIP_ACTIVITIES_MONITORING_STATUS: "VIEW_PSEA_CSIP_ACTIVITIES_MONITORING_STATUS",
  VIEW_PSEA_CSIP_DOCUMENTS: "VIEW_PSEA_CSIP_DOCUMENTS",
  VIEW_PSEA_CSIP_SNAPSHOTS: "VIEW_PSEA_CSIP_SNAPSHOTS",
  VIEW_PSEA_OBSERVATIONS: "VIEW_PSEA_OBSERVATIONS",
  SPECIAL_PSEA_PERMISSIONS: "SPECIAL_PSEA_PERMISSIONS",
};

export const AGENCY_PERMISSIONS = {
  // User Management
  RESET_CSO_USER_PASSWORD: "RESET_CSO_USER_PASSWORD",

  UCN_CONVERT_TO_DSR: "UCN_CONVERT_TO_DSR",

  // General
  CSO_LIST_AND_DETAIL_VIEW: "CSO_LIST_AND_DETAIL_VIEW", // ALL USERS HAVE ACCESS

  // Create Draft CFEI for Own Agency
  CFEI_DRAFT_CREATE: "CFEI_DRAFT_CREATE",
  CFEI_DRAFT_MANAGE: "CFEI_DRAFT_MANAGE", // # Save, edit, delete, manage CSO's, focal points - if is the creator
  CFEI_DRAFT_SEND_TO_FOCAL_POINT_TO_PUBLISH: "CFEI_DRAFT_SEND_TO_FOCAL_POINT_TO_PUBLISH", // If is the creator

  // Publish Draft CFEI for Own Agency
  CFEI_SENT_EDIT: "CFEI_SENT_EDIT", // If creator / focal point
  CFEI_SENT_INVITE_CSO: "CFEI_SENT_INVITE_CSO", // If creator / focal point
  CFEI_SENT_PUBLISH: "CFEI_SENT_PUBLISH", // If focal point
  CFEI_PUBLISH: "CFEI_PUBLISH", // If creator

  // Modify Published CFEI for Own Agency
  CFEI_PUBLISHED_VIEW_AND_ANSWER_CLARIFICATION_QUESTIONS: "CFEI_PUBLISHED_VIEW_AND_ANSWER_CLARIFICATION_QUESTIONS", // If creator / focal point
  CFEI_PUBLISHED_EDIT_DATES: "CFEI_PUBLISHED_EDIT_DATES", // If creator / focal point
  CFEI_PUBLISHED_INVITE_CSO: "CFEI_PUBLISHED_INVITE_CSO", // If creator / focal point
  CFEI_PUBLISHED_CANCEL: "CFEI_PUBLISHED_CANCEL", // If creator / focal point

  // Assess Applications Submitted for CFEI Published by Own Agency
  CFEI_MANAGE_REVIEWERS: "CFEI_MANAGE_REVIEWERS", // If creator / focal point
  CFEI_VIEW_APPLICATIONS: "CFEI_VIEW_APPLICATIONS", // If creator / focal point
  CFEI_PRESELECT_APPLICATIONS: "CFEI_PRESELECT_APPLICATIONS", // If creator / focal point
  CFEI_REVIEW_APPLICATIONS: "CFEI_REVIEW_APPLICATIONS", // If creator / focal point
  CFEI_VIEW_ALL_REVIEWS: "CFEI_VIEW_ALL_REVIEWS", // If creator / focal point
  CFEI_ADD_REVIEW_SUMMARY: "CFEI_ADD_REVIEW_SUMMARY", // If creator / focal point
  CFEI_RECOMMEND_PARTNER_FOR_SELECTION: "CFEI_RECOMMEND_PARTNER_FOR_SELECTION", // If creator / focal point

  // Approve Partner Selection for CFEI Published by Own Agency
  CFEI_SELECT_RECOMMENDED_PARTNER: "CFEI_SELECT_RECOMMENDED_PARTNER", // If focal point
  CFEI_SELECT_PARTNER: "CFEI_SELECT_PARTNER", // If creator
  CFEI_DESELECT_PARTNER: "CFEI_DESELECT_PARTNER", // If creator / focal point

  // Finalize CFEI Published by Own agency
  CFEI_FINALIZE: "CFEI_FINALIZE", // If creator / focal point
  CFEI_FINALIZED_VIEW_ALL_REVIEWS: "CFEI_FINALIZED_VIEW_ALL_REVIEWS", // If creator / focal point / reviewer
  CFEI_FINALIZED_VIEW_WINNER_AND_CN: "CFEI_FINALIZED_VIEW_WINNER_AND_CN", // View results tab of finalized CFEI for all agencies: Selected CSO & CN
  CFEI_FINALIZED_VIEW_ALL_INFO: "CFEI_FINALIZED_VIEW_ALL_INFO",

  // Direct Selection/Retention

  CFEI_DIRECT_CREATE_DRAFT_MANAGE_FOCAL_POINTS: "CFEI_DIRECT_CREATE_DRAFT_MANAGE_FOCAL_POINTS",
  CFEI_DIRECT_VIEW_OVERVIEW_WITH_JUSTIFICATION: "CFEI_DIRECT_VIEW_OVERVIEW_WITH_JUSTIFICATION",
  CFEI_DIRECT_INDICATE_CSO: "CFEI_DIRECT_INDICATE_CSO",
  CFEI_DIRECT_SAVE_DRAFT: "CFEI_DIRECT_SAVE_DRAFT",
  CFEI_DIRECT_EDIT_DRAFT: "CFEI_DIRECT_EDIT_DRAFT",
  CFEI_DIRECT_DELETE_DRAFT: "CFEI_DIRECT_DELETE_DRAFT",
  CFEI_DIRECT_SEND_DRAFT_TO_FOCAL_POINT: "CFEI_DIRECT_SEND_DRAFT_TO_FOCAL_POINT",
  CFEI_DIRECT_EDIT_SENT: "CFEI_DIRECT_EDIT_SENT",
  CFEI_DIRECT_PUBLISH: "CFEI_DIRECT_PUBLISH",
  CFEI_DIRECT_EDIT_PUBLISHED: "CFEI_DIRECT_EDIT_PUBLISHED",
  CFEI_DIRECT_CANCEL: "CFEI_DIRECT_CANCEL",

  // Sanctions check
  REVIEW_AND_MARK_SANCTIONS_MATCHES: "REVIEW_AND_MARK_SANCTIONS_MATCHES",

  // Conduct Verification
  VERIFY_INGO_HQ: "VERIFY_INGO_HQ",
  VERIFY_CSOS_GLOBALLY: "VERIFY_CSOS_GLOBALLY",
  VERIFY_CSOS_FOR_OWN_COUNTRY: "VERIFY_CSOS_FOR_OWN_COUNTRY",
  VERIFY_SEE_COMMENTS: "VERIFY_SEE_COMMENTS",

  // View Observation & risk flags
  VIEW_PROFILE_OBSERVATION_FLAG_COUNT: "VIEW_PROFILE_OBSERVATION_FLAG_COUNT", // done POST_REFACTORING_TODO: remove observation tab
  VIEW_PROFILE_OBSERVATION_FLAG_COMMENTS: "VIEW_PROFILE_OBSERVATION_FLAG_COMMENTS",

  // Add Observation & risk flags all
  RESOLVE_ESCALATED_FLAG_ALL_CSO_PROFILES: "RESOLVE_ESCALATED_FLAG_ALL_CSO_PROFILES",
  ADD_FLAG_OBSERVATION_ALL_CSO_PROFILES: "ADD_FLAG_OBSERVATION_ALL_CSO_PROFILES",
  ADD_FLAG_OBSERVATION_COUNTRY_CSO_PROFILES: "ADD_FLAG_OBSERVATION_COUNTRY_CSO_PROFILES", // Users country must match CSOs country

  // Reports
  RUN_REPORT_CSO_PROFILE: "RUN_REPORT_CSO_PROFILE",
  RUN_REPORT_CSO_MAPPING: "RUN_REPORT_CSO_MAPPING",
  RUN_REPORT_CSO_CONTACT: "RUN_REPORT_CSO_CONTACT",
  RUN_REPORT_CFEI_MANAGEMENT: "RUN_REPORT_CFEI_MANAGEMENT",
  RUN_REPORT_VERIFICATION_AND_FLAGGING: "RUN_REPORT_VERIFICATION_AND_FLAGGING",
  RUN_REPORT_PSEA_REPORT: "RUN_REPORT_PSEA_REPORT",

  // ERP
  ERP_ENTER_VENDOR_NUMBER: "ERP_ENTER_VENDOR_NUMBER",

  SPECIAL_PARTNER_CREATION: "SPECIAL_PARTNER_CREATION", //UNFPA - EVERYONE BUT READER
  SPECIAL_PARTNER_DELETION: "SPECIAL_PARTNER_DELETION", //UNFPA - HQ EDITOR

  // PSEA

  VIEW_PSEA_INITIAL_ASSESSMENT: "VIEW_PSEA_INITIAL_ASSESSMENT",
  EDIT_PSEA_INITIAL_ASSESSMENT: "EDIT_PSEA_INITIAL_ASSESSMENT",
  VIEW_PSEA_SELF_ASSESSMENT: "VIEW_PSEA_SELF_ASSESSMENT",
  EDIT_PSEA_SELF_ASSESSMENT: "EDIT_PSEA_SELF_ASSESSMENT",
  VIEW_PSEA_SELF_ASSESSMENT_RATING: "VIEW_PSEA_SELF_ASSESSMENT_RATING",
  VIEW_PSEA_SELF_ASSESSMENT_DOCUMENTS: "VIEW_PSEA_SELF_ASSESSMENT_DOCUMENTS",
  EDIT_PSEA_SELF_ASSESSMENT_DOCUMENTS: "EDIT_PSEA_SELF_ASSESSMENT_DOCUMENTS",
  VIEW_PSEA_SELF_ASSESSMENT_SNAPSHOTS: "VIEW_PSEA_SELF_ASSESSMENT_SNAPSHOTS",
  UNLOCK_PSEA_SELF_ASSESSMENT_PARTNER_SECTION: "UNLOCK_PSEA_SELF_ASSESSMENT_PARTNER_SECTION",
  UNLOCK_PSEA_SELF_ASSESSMENT_AGENCY_SECTION: "UNLOCK_PSEA_SELF_ASSESSMENT_AGENCY_SECTION",
  COMPLETE_PSEA_SELF_ASSESSMENT: "COMPLETE_PSEA_SELF_ASSESSMENT",
  VIEW_PSEA_CSIP: "VIEW_PSEA_CSIP",
  EXTEND_PSEA_CSIP_DEADLINE: "EXTEND_PSEA_CSIP_DEADLINE",
  DOWNLOAD_PSEA_CSIP_AS_RFT: "DOWNLOAD_PSEA_CSIP_AS_RFT",
  VIEW_PSEA_CSIP_ACTIVITIES: "VIEW_PSEA_CSIP_ACTIVITIES",
  EDIT_PSEA_CSIP_ACTIVITIES: "EDIT_PSEA_CSIP_ACTIVITIES",
  VIEW_PSEA_CSIP_ACTIVITIES_MONITORING_STATUS: "VIEW_PSEA_CSIP_ACTIVITIES_MONITORING_STATUS",
  EDIT_PSEA_CSIP_ACTIVITIES_MONITORING_STATUS: "EDIT_PSEA_CSIP_ACTIVITIES_MONITORING_STATUS",
  VIEW_PSEA_CSIP_DOCUMENTS: "VIEW_PSEA_CSIP_DOCUMENTS",
  EDIT_PSEA_CSIP_DOCUMENTS: "EDIT_PSEA_CSIP_DOCUMENTS",
  VIEW_PSEA_CSIP_SNAPSHOTS: "VIEW_PSEA_CSIP_SNAPSHOTS",
  COMPLETE_PSEA_CSIP: "COMPLETE_PSEA_CSIP",
  VIEW_PSEA_FINAL_DETERMINATION: "VIEW_PSEA_FINAL_DETERMINATION",
  VIEW_PSEA_OBSERVATIONS: "VIEW_PSEA_OBSERVATIONS",
  SPECIAL_PSEA_PERMISSIONS: "SPECIAL_PSEA_PERMISSIONS",
};

export const COMMON_PERMISSIONS = {
  ADMIN_PANEL: "ADMIN_PANEL",
  MANAGE_USERS: "MANAGE_USERS",
  RECEIVE_NOTIFICATIONS: "RECEIVE_NOTIFICATIONS",
  CFEI_FINALIZE: "CFEI_FINALIZE", // DONE  FOR DSR
  VIEW_DASHBOARD: "VIEW_DASHBOARD", // ALL USERS HAVE ACCESS
  CFEI_VIEW: "CFEI_VIEW", // ALL USERS HAVE ACCESS
  VIEW_IP_AGREEMENT_DASHBOARD: "VIEW_IP_AGREEMENT_DASHBOARD",
};
